import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import FileSaver from 'file-saver';
import { cast } from 'mobx-state-tree';
import { observer } from 'mobx-react';
import moment from 'moment';
import {
  DatePicker,
  Select,
  Tag,
  Tooltip,
  Skeleton,
  Button,
  List,
  Breadcrumb,
  notification,
  Input,
  Collapse,
  Popconfirm,
} from 'antd';
import { FaTrademark, FaMoneyBillWaveAlt, FaToolbox } from 'react-icons/fa';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { Card, CardHeader, CardBody } from 'reactstrap';

import { useMst } from '../../store/RootStore';
import config from '../../config/config';
import { RowWrapper as AssetItemRowWrapper } from '../../components/AssetsSelect/styles';
import { FormSection, FormRow, Page, EmptyMessage, AssetRepairHistory } from '../../components';
import {
  UserType,
  MaintenanceRequestItemType,
  MaintenanceRequestHeaderType,
  MaintenanceRequestTypeModelType,
} from '../../types';
import DashboardLayout from '../../layouts/Dashboard';

const { Panel } = Collapse;

const arlSchema = yup.object().shape({
  types: yup.array().of(
    yup.object().shape({
      items: yup.array().of(
        yup.object({
          status: yup.number(),
          approver1Remarks: yup.string().when('status', {
            is: config.maintenanceRequestItemStatus.REJECTED,
            then: yup.string().required('Remarks is required'),
            otherwise: yup.string(),
          }),
        }),
      ),
    }),
  ),
});

const rdoSchema = yup.object().shape({
  types: yup.array().of(
    yup.object().shape({
      items: yup.array().of(
        yup.object({
          status: yup.number(),
          approver2Remarks: yup.string().when('status', {
            is: config.maintenanceRequestItemStatus.REJECTED,
            then: yup.string().required('Remarks is required'),
            otherwise: yup.string(),
          }),
        }),
      ),
    }),
  ),
});

const engineeringAdminSchema = yup.object().shape({
  types: yup.array().of(
    yup.object().shape({
      requestType: yup.number(),
      technicians: yup.array().when(['requestType', 'items'], {
        is: (requestType, items: MaintenanceRequestItemType[]) =>
          requestType === config.maintenanceRequestTypes.INHOUSE &&
          items.filter(item => item.status === config.maintenanceRequestItemStatus.APPROVED).length > 0,
        then: yup
          .array(yup.object().shape({ id: yup.number().required() }))
          .required('Assigned technician is required'),
        otherwise: yup.array(),
      }),
      thirdPartyTechnician: yup.object().when(['requestType', 'items'], {
        is: (requestType, items: MaintenanceRequestItemType[]) =>
          requestType === config.maintenanceRequestTypes.VENDOR &&
          items.filter(
            (item: MaintenanceRequestItemType) => item.status === config.maintenanceRequestItemStatus.APPROVED,
          ).length > 0,
        then: yup.object().required('Assigned technician is required'),
        otherwise: yup.object().nullable(),
      }),
      slaType: yup.number().required(),
      dateOfService: yup.string().when('items', {
        is: (items: MaintenanceRequestItemType[]) =>
          items.filter(item => item.status === config.maintenanceRequestItemStatus.APPROVED).length > 0,
        then: yup.string().required('Date of service is required'),
        otherwise: yup.string().nullable(),
      }),
      items: yup.array().of(
        yup.object({
          status: yup.number(),
          approver3Remarks: yup.string().when('status', {
            is: config.maintenanceRequestItemStatus.REJECTED,
            then: yup.string().required('Remarks is required'),
            otherwise: yup.string(),
          }),
        }),
      ),
    }),
  ),
});

const engineeringSupervisorSchema = yup.object().shape({
  requestType: yup.number(),
  technicians: yup.array().when(['requestType', 'items'], {
    is: (requestType, items: MaintenanceRequestItemType[]) =>
      requestType === config.maintenanceRequestTypes.INHOUSE &&
      items.filter(item => item.status === config.maintenanceRequestItemStatus.APPROVED).length > 0,
    then: yup.array(yup.object().shape({ id: yup.number().required() })).required('Assigned technician is required'),
    otherwise: yup.array(),
  }),
  thirdPartyTechnician: yup.object().when(['requestType', 'items'], {
    is: (requestType, items: MaintenanceRequestItemType[]) =>
      requestType === config.maintenanceRequestTypes.VENDOR &&
      items.filter((item: MaintenanceRequestItemType) => item.status === config.maintenanceRequestItemStatus.APPROVED)
        .length > 0,
    then: yup.object().required('Assigned technician is required'),
    otherwise: yup.object().nullable(),
  }),
  slaType: yup.number().required(),
  dateOfService: yup.string().when('items', {
    is: (items: MaintenanceRequestItemType[]) =>
      items.filter(item => item.status === config.maintenanceRequestItemStatus.APPROVED).length > 0,
    then: yup.string().required('Date of service is required'),
    otherwise: yup.string().nullable(),
  }),
  items: yup.array().of(
    yup.object({
      status: yup.number(),
      approver3Remarks: yup.string().when('status', {
        is: config.maintenanceRequestItemStatus.REJECTED,
        then: yup.string().required('Remarks is required'),
        otherwise: yup.string(),
      }),
    }),
  ),
});

const index: React.FC = observer(() => {
  const store = useMst();
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [assetHistoryVisible, setAssetHistoryVisible] = useState(false);
  const [selectedAssetHistory, setSelectedAssetHistory] = useState(-1);
  const [isForARLApproval, setIsForARLApproval] = useState(false);
  const [isForRDOApproval, setIsForRDOApproval] = useState(false);
  const [isForEngSupervisorApproval, setIsForEngSupervisorApproval] = useState(false);
  const [submitFormLoading, setSubmitFormLoading] = useState(false);
  const [maintenanceRequest, setMaintenanceRequest] = useState<null | MaintenanceRequestHeaderType>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [selectedServiceTechnicians, setSelectedServiceTechnicians] = useState<{ [key: string]: UserType }>({});

  useEffect(() => {
    setLoading(true);
    // load request details here
    store.userAccount
      .getMaintenanceRequest(id)
      .then(response => {
        const data = { ...response.data.data };
        if (store.userAccount.user) {
          // check request is still pending and if current user is approver
          if (
            data.status === config.maintenanceRequestStatuses.PENDING &&
            store.userAccount.user.id == data.userApproverMap?.mrApprover1.id
          ) {
            setIsForARLApproval(true);
          }
          if (
            data.status === config.maintenanceRequestStatuses.ARL_APPROVED &&
            store.userAccount.user.id == data.userApproverMap?.mrApprover2.id
          ) {
            setIsForRDOApproval(true);
            data.types = cast(
              data.types.filter(type => type.status === config.maintenanceRequestTypeStatuses.ARL_APPROVED),
            );
            data.types.forEach((type, index) => {
              data.types[index].items = cast(
                type.items.filter(item => item.status === config.maintenanceRequestItemStatus.APPROVED),
              );
            });
          }
          if (
            data.status === config.maintenanceRequestStatuses.RDO_APPROVED &&
            store.userAccount.user.id == data.userApproverMap?.mrApprover3.id
          ) {
            setIsForEngSupervisorApproval(true);

            data.types = cast(
              data.types.filter(type => type.status >= config.maintenanceRequestTypeStatuses.RDO_APPROVED),
            );
            data.types.forEach((type, index) => {
              data.types[index].items = cast(
                type.items.filter(item => item.status === config.maintenanceRequestItemStatus.APPROVED),
              );
            });
          }
        }
        setMaintenanceRequest(data);
        setLoading(false);
      })
      .catch(error => {
        notification['error']({
          message: 'Maintenance Request',
          description: 'Request does not exist',
        });
        history.push('/maintenance_requests');
      });
  }, []);

  const displayAssetHistory = (assetId: number) => {
    setSelectedAssetHistory(assetId);
    setAssetHistoryVisible(true);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let errors: { [key: string]: string } = {};
    try {
      if (isForARLApproval) {
        await arlSchema.validate(maintenanceRequest, { abortEarly: false });
      } else if (isForRDOApproval) {
        await rdoSchema.validate(maintenanceRequest, { abortEarly: false });
      }
    } catch (err) {
      errors = err.inner.reduce((prev: { [key: string]: string }, current: any) => {
        console.log(prev, current);
        prev[current.path] = current.message;
        return prev;
      }, {});
    }
    setErrors(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setSubmitFormLoading(true);

    try {
      console.log(JSON.stringify(maintenanceRequest));
      if (isForARLApproval) {
        const response = await store.userAccount.arlProcessMaintenanceRequest(maintenanceRequest);
        notification['success']({
          message: 'Maintenance Request',
          description: 'Successfully processed the request',
        });
        setMaintenanceRequest(response.data.data);
        setIsForARLApproval(false);
      } else if (isForRDOApproval) {
        const response = await store.userAccount.rdoProcessMaintenanceRequest(maintenanceRequest);
        notification['success']({
          message: 'Maintenance Request',
          description: 'Successfully processed the request',
        });
        setMaintenanceRequest(response.data.data);
        setIsForRDOApproval(false);
      }
    } catch (e) {
      console.log(e);
      notification['error']({
        message: 'Maintenance Request',
        description: 'An error occurred while processing your request, please try again later',
      });
    }
    setSubmitFormLoading(false);
  };

  const handleProcessServiceRequestByEngineeringSupervisor = async (type: MaintenanceRequestTypeModelType) => {
    if (maintenanceRequest) {
      let errors: { [key: string]: string } = {};
      try {
        await engineeringSupervisorSchema.validate(type, { abortEarly: false });
      } catch (err) {
        notification['error']({
          message: 'Maintenance Request',
          description: 'Please complete the form',
        });
        errors = err.inner.reduce((prev: { [key: string]: string }, current: any) => {
          console.log(prev, current);
          prev[current.path] = current.message;
          return prev;
        }, {});
      }
      setErrors(errors);

      if (Object.keys(errors).length) {
        return;
      }

      setSubmitFormLoading(true);

      const response = await store.userAccount.engineeringSupervisorProcessMaintenanceRequest(
        maintenanceRequest.uuid,
        type,
      );
      notification['success']({
        message: 'Maintenance Request',
        description: `Successfully processed the service request ${type.referenceNumber}`,
      });
      const mrHeader = response.data.data;
      setMaintenanceRequest(mrHeader);

      if (mrHeader.status == config.maintenanceRequestStatuses.WORK_IN_PROGRESS) {
        setIsForEngSupervisorApproval(false);
      }

      setSubmitFormLoading(false);
    }
  };

  const handleCancelRequest = async () => {
    try {
      const response = await store.userAccount.cancelMaintenanceRequest(maintenanceRequest);
      const updatedMR = response.data.data;
      setMaintenanceRequest(updatedMR);
      notification['success']({
        message: 'Maintenance Request',
        description: 'You have cancelled this request',
      });
    } catch (e) {
      console.log(e);
      notification['error']({
        message: 'Maintenance Request',
        description: 'An error occurred while processing your request, please try again later',
      });
    }
  };

  console.log('MR', maintenanceRequest);
  console.log('TYPES', maintenanceRequest?.types);

  const location = useLocation();
  const { fromRepairsSummaryReport, ...rest } = location?.state || {};

  return (
    <DashboardLayout>
      <Page className="maintent-request-view">
        <form onSubmit={handleSubmit}>
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item>
              <Link to="/maintenance_requests">Maintenance Requests</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{maintenanceRequest ? maintenanceRequest.referenceNumber : ''}</Breadcrumb.Item>
          </Breadcrumb>
          <AssetRepairHistory
            assetId={selectedAssetHistory}
            visible={assetHistoryVisible}
            onClose={() => setAssetHistoryVisible(false)}
          />
          {loading ? (
            <>
              <Card>
                <div className="card-body">
                  <Skeleton active />
                  <Skeleton active />
                </div>
              </Card>
            </>
          ) : (
            <div>
              <Card>
                {fromRepairsSummaryReport && (
                  <div className="m-2">
                    <Link to={{
                      pathname: '/reports/repair_summary',
                      state: rest
                    }}>&laquo; Back to Repairs Summary Report</Link>
                  </div>
                )}
                <CardHeader>Maintenance request</CardHeader>
                <CardBody>
                  <div className="card-title d-flex justify-content-between">
                    {store.userAccount.user?.id === maintenanceRequest?.user.id &&
                      maintenanceRequest?.status === config.maintenanceRequestStatuses.PENDING ? (
                      <Popconfirm
                        title="Are you sure you want to cancel this request?"
                        onConfirm={() => handleCancelRequest()}
                      >
                        <Button type="primary" size="small" danger>
                          Cancel Request
                        </Button>
                      </Popconfirm>
                    ) : null}
                  </div>
                  <FormSection >
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <FormRow marginBottom="10">
                          <div className="controls --inline">
                            <label className="label">Store:</label>
                            <span className="value">
                              {maintenanceRequest?.user.firstName}
                              {maintenanceRequest?.user.lastName}
                            </span>
                          </div>
                        </FormRow>
                        <FormRow marginBottom="10">
                          <div className="controls --inline">
                            <label className="label">Requestor name:</label>
                            <span className="value">{maintenanceRequest?.requestorName}</span>
                          </div>
                        </FormRow>
                        <FormRow marginBottom="10">
                          <div className="controls --inline">
                            <label className="label">Reference Number:</label>
                            <span className="value">{maintenanceRequest?.referenceNumber}</span>
                          </div>
                        </FormRow>
                        <FormRow marginBottom="10">
                          <div className="controls --inline">
                            <label className="label">Date applied:</label>
                            <span className="value">
                              {maintenanceRequest?.createdAt
                                ? moment(maintenanceRequest?.createdAt).format('YYYY-MM-DD')
                                : ''}
                            </span>
                          </div>
                        </FormRow>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <FormRow marginBottom="10">
                          <div className="controls --inline">
                            <label className="label">Status:</label>
                            <span className="value">
                              {maintenanceRequest && config.maintenanceRequestStatusLabels[maintenanceRequest?.status]}
                            </span>
                          </div>
                        </FormRow>
                        {maintenanceRequest && maintenanceRequest?.dateProcessedByApprover1 ? (
                          <FormRow marginBottom="10">
                            <div className="controls --inline">
                              <label className="label">Date Processed by ARL:</label>
                              <span className="value">
                                {moment(maintenanceRequest?.dateProcessedByApprover1).format('LLL')}
                              </span>
                            </div>
                          </FormRow>
                        ) : null}
                        {maintenanceRequest && maintenanceRequest?.dateProcessedByApprover2 ? (
                          <FormRow marginBottom="10">
                            <div className="controls --inline">
                              <label className="label">Date Processed by RDO:</label>
                              <span className="value">
                                {moment(maintenanceRequest?.dateProcessedByApprover2).format('LLL')}
                              </span>
                            </div>
                          </FormRow>
                        ) : null}
                        {maintenanceRequest && maintenanceRequest?.dateProcessedByApprover3 ? (
                          <FormRow marginBottom="10">
                            <div className="controls --inline">
                              <label className="label">Date Processed by Eng Supervisor:</label>
                              <span className="value">
                                {moment(maintenanceRequest?.dateProcessedByApprover3).format('LLL')}
                              </span>
                            </div>
                          </FormRow>
                        ) : null}
                      </div>

                    </div>
                  </FormSection>
                </CardBody>
              </Card>

              {maintenanceRequest?.types.map((type, typeIndex) => {
                return (
                  <Collapse defaultActiveKey={['1']} bordered={false} style={{ marginBottom: 20 }}>
                    <Panel header={`Service Request: ${type.referenceNumber}`} key="1">
                      <Card key={type.referenceNumber}>
                        <div className="card-body">
                          <div className="card-title d-flex justify-content-between">
                            <span>Request Details</span>
                            {type.status >= config.maintenanceRequestTypeStatuses.FOR_STORE_APPROVAL ? (
                              <span>
                                <Button
                                  onClick={() => history.push(`/work_management/${type.referenceNumber}`)}
                                  size="small"
                                  type="link"
                                  className="primary mr-2"
                                >
                                  View work
                                </Button>

                                <Button
                                  size="small"
                                  onClick={async () => {
                                    if (type) {
                                      const response = await store.userAccount.downloadMRForm(type.referenceNumber);
                                      const blob = new Blob([response.data], {
                                        type: 'application/pdf',
                                      });
                                      FileSaver.saveAs(blob, `service-request-${type.referenceNumber}.pdf`);
                                    }
                                  }}
                                  type="link"
                                >
                                  Download Form
                                </Button>
                              </span>
                            ) : null}
                          </div>
                          <FormSection>
                            <FormRow marginBottom="10">
                              <div className="controls --inline">
                                <label className="label">Status:</label>
                                <span className="value">
                                  <Tag color={config.maintenanceRequestTypeStatusLabels[type.status].color}>
                                    {config.maintenanceRequestTypeStatusLabels[type.status].label}
                                  </Tag>
                                </span>
                              </div>
                            </FormRow>
                            <FormRow marginBottom="10">
                              <div className="controls --inline">
                                <label className="label">Request Number:</label>
                                <span className="value">{type.referenceNumber}</span>
                              </div>
                            </FormRow>

                            <FormRow marginBottom="10">
                              <div className="controls --inline">
                                <label className="label">Request Type:</label>
                                {isForEngSupervisorApproval &&
                                  type.status == config.maintenanceRequestTypeStatuses.RDO_APPROVED ? (
                                  <Select
                                    style={{ minWidth: 200 }}
                                    placeholder="Select type"
                                    onChange={value => {
                                      const mr = { ...maintenanceRequest };
                                      const types = [...mr.types];
                                      const newType = { ...types[typeIndex] };
                                      newType.requestType = parseInt(value);
                                      types[typeIndex] = newType;
                                      mr.types = cast(types);
                                      setMaintenanceRequest(mr);
                                    }}
                                    defaultValue={config.maintenanceRequestTypeLabels[type.requestType]}
                                  >
                                    <Select.Option key="inhouse" value="0">
                                      In-house
                                    </Select.Option>
                                    <Select.Option key="vendor" value="1">
                                      Vendor
                                    </Select.Option>
                                  </Select>
                                ) : (
                                  <span className="value">{config.maintenanceRequestTypeLabels[type.requestType]}</span>
                                )}
                              </div>
                            </FormRow>

                            {type.slaType && type.status >= config.maintenanceRequestTypeStatuses.FOR_STORE_APPROVAL ? (
                              <FormRow marginBottom="10">
                                <div className="controls --inline">
                                  <label className="label">SLA Type:</label>
                                  <span className="value">Priority {type.slaType}</span>
                                </div>
                              </FormRow>
                            ) : null}

                            {type.dateOfService &&
                              type.status >= config.maintenanceRequestTypeStatuses.FOR_STORE_APPROVAL ? (
                              <FormRow marginBottom="10">
                                <div className="controls --inline">
                                  <label className="label">Date of Service:</label>
                                  <span className="value">
                                    {moment(type.dateOfService).format('YYYY-MM-DD h:mm a')}
                                  </span>
                                </div>
                              </FormRow>
                            ) : null}

                            {type.technicians.length &&
                              type.status >= config.maintenanceRequestTypeStatuses.FOR_STORE_APPROVAL ? (
                              <FormRow marginBottom="10">
                                <div className="controls">
                                  <label className="label">Assigned Technician/s:</label>
                                  <ul className="value">
                                    {type.technicians.map(technician => {
                                      return (
                                        <li key={technician.id}>
                                          {technician.firstName} {technician.lastName}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </FormRow>
                            ) : null}
                          </FormSection>
                        </div>
                      </Card>

                      {isForEngSupervisorApproval &&
                        type.status === config.maintenanceRequestTypeStatuses.RDO_APPROVED && (
                          <Card key={`input-${type.referenceNumber}`}>
                            <div className="card-body">
                              <div className="card-title">Set Date of service and assign technicians</div>
                              <div>
                                <FormSection>
                                  <FormRow className={errors[`types[${typeIndex}].slaType`] ? 'has-error' : ''}>
                                    <div className="d-flex flex-direction-row justify-content-between">
                                      <div>
                                        <label className="form-title">SLA type:</label>
                                        <Select
                                          onChange={value => {
                                            const tmp: MaintenanceRequestHeaderType = { ...maintenanceRequest };
                                            tmp.types[typeIndex].slaType = parseInt(value);
                                            setMaintenanceRequest(tmp);
                                          }}
                                          style={{ minWidth: 230 }}
                                          placeholder="Select SLA"
                                          defaultValue="4"
                                        >
                                          <Select.Option key="priority-1" value="1">
                                            Priority 1
                                          </Select.Option>
                                          <Select.Option key="priority-2" value="2">
                                            Priority 2
                                          </Select.Option>
                                          <Select.Option key="priority-3" value="3">
                                            Priority 3
                                          </Select.Option>
                                          <Select.Option key="priority-4" value="4">
                                            Priority 4
                                          </Select.Option>
                                        </Select>
                                        {errors[`slaType`] ? (
                                          <span className="error-message mt-2">SLA Type is required</span>
                                        ) : null}
                                      </div>
                                    </div>
                                  </FormRow>
                                  <FormRow>
                                    <label htmlFor="" className="label">
                                      Service date:
                                    </label>

                                    <div className="controls --inline">
                                      <DatePicker
                                        format="YYYY-MM-DD h:mm a"
                                        showTime
                                        onChange={(value, dateString) => {
                                          const tmp: MaintenanceRequestHeaderType = { ...maintenanceRequest };
                                          tmp.types[typeIndex].dateOfService = dateString;
                                          setMaintenanceRequest(tmp);
                                        }}
                                      />
                                    </div>
                                    {errors[`dateOfService`] ? (
                                      <span className="error-message mt-2">
                                        {/* {errors[`types[${typeIndex}].dateOfService`]} */}
                                        Date of service is required
                                      </span>
                                    ) : null}
                                  </FormRow>
                                  <FormRow>
                                    <label htmlFor="" className="label">
                                      Technician:
                                    </label>
                                    <div className="controls --inline">
                                      {type.requestType === config.maintenanceRequestTypes.INHOUSE ? (
                                        <>
                                          <Select
                                            style={{ width: 240 }}
                                            onChange={(value: number) => {
                                              const technician = store.references.engineeringUsers.filter(
                                                t => t.id === value,
                                              )[0];
                                              setSelectedServiceTechnicians({
                                                ...selectedServiceTechnicians,
                                                [type.referenceNumber]: technician,
                                              });
                                            }}
                                          >
                                            {store.references.engineeringUsers.map(user => (
                                              <Select.Option key={user.id} value={user.id}>
                                                {user.firstName} {user.lastName} ({user.email})
                                              </Select.Option>
                                            ))}
                                          </Select>
                                          <Button
                                            type="link"
                                            onClick={() => {
                                              const tmp: MaintenanceRequestHeaderType = { ...maintenanceRequest };
                                              const technicians = tmp.types[typeIndex].technicians;
                                              const selectedTechnician =
                                                selectedServiceTechnicians[tmp.types[typeIndex].referenceNumber];

                                              if (!selectedTechnician) return;

                                              if (
                                                technicians.filter(t => t.id === selectedTechnician.id).length === 0
                                              ) {
                                                const types = tmp.types.map((type, index) => {
                                                  if (typeIndex === index) {
                                                    return {
                                                      ...type,
                                                      technicians: [...type.technicians, selectedTechnician],
                                                    };
                                                  } else {
                                                    return type;
                                                  }
                                                });
                                                tmp.types = cast(types);
                                                setMaintenanceRequest(tmp);
                                              }
                                            }}
                                          >
                                            Add Technician
                                          </Button>
                                        </>
                                      ) : (
                                        <Select
                                          style={{ width: 340 }}
                                          onChange={value => {
                                            const tmp: MaintenanceRequestHeaderType = { ...maintenanceRequest };
                                            tmp.types[typeIndex].thirdPartyTechnician = {
                                              rating: 0,
                                              remarks: '',
                                              totalCost: 0,
                                              workDone: '',
                                              vendor: store.references.thirdPartyTechnicians.filter(
                                                u => u.id === value,
                                              )[0],
                                            };
                                            setMaintenanceRequest(tmp);
                                          }}
                                        >
                                          {store.references.thirdPartyTechnicians.map(technician => (
                                            <Select.Option key={technician.id} value={technician.id}>
                                              {technician.name}
                                            </Select.Option>
                                          ))}
                                        </Select>
                                      )}
                                    </div>
                                    {errors[`thirdPartyTechnician`] ? (
                                      <span className="error-message mt-2">
                                        {/* {errors[`types[${typeIndex}].thirdPartyTechnician`]} */}
                                        Technician is required
                                      </span>
                                    ) : null}
                                  </FormRow>

                                  {type.requestType === config.maintenanceRequestTypes.INHOUSE ? (
                                    <FormRow>
                                      {type.technicians.length ? (
                                        <div style={{ padding: 15, border: '1px solid rgba(0,0,0,0.05)' }}>
                                          <label htmlFor="" className="label">
                                            Assigned technicians:
                                          </label>
                                          <List size="small">
                                            {type.technicians.map(technician => (
                                              <List.Item key={technician.id}>
                                                <span>
                                                  {technician.firstName} {technician.lastName} ({technician.email})
                                                </span>
                                                <Button
                                                  type="link"
                                                  danger
                                                  onClick={() => {
                                                    const tmp: MaintenanceRequestHeaderType = { ...maintenanceRequest };
                                                    const types = tmp.types.map((tmpType, index) => {
                                                      if (typeIndex === index) {
                                                        return {
                                                          ...tmpType,
                                                          technicians: tmpType.technicians.filter(
                                                            t => t.id !== technician.id,
                                                          ),
                                                        };
                                                      }
                                                      return tmpType;
                                                    });
                                                    tmp.types = cast(types);
                                                    setMaintenanceRequest(tmp);
                                                  }}
                                                >
                                                  Remove
                                                </Button>
                                              </List.Item>
                                            ))}
                                          </List>
                                        </div>
                                      ) : (
                                        <EmptyMessage>
                                          <span className="text"> No assigned technician yet.</span>
                                        </EmptyMessage>
                                      )}
                                    </FormRow>
                                  ) : null}
                                  {errors[`technicians`] ? (
                                    <span className="error-message mt-2">Please assign at least one technician</span>
                                  ) : null}
                                </FormSection>
                              </div>
                            </div>
                          </Card>
                        )}

                      <Card key={`items-${type.referenceNumber}`}>
                        <CardHeader>Request Assets</CardHeader>
                        <CardBody>
                          <div className="card-title"></div>
                          <div>
                            {type.items.map((item, itemIndex) => {
                              const asset = item.asset;
                              return (
                                <AssetItemRowWrapper
                                  key={asset.assetId}
                                  style={{
                                    padding: 0,
                                    borderRadius: 5,
                                    borderBottom: '1px solid #ccc',
                                    marginBottom: 10,
                                  }}
                                >
                                  <div
                                    className="d-flex item-details-content"
                                    style={{ background: 'rgba(0,0,0,0.05)', padding: 20 }}
                                  >
                                    <div className="image-wrapper d-flex flex-column">
                                      <img src={item.photo} style={{ width: '100%' }} />
                                      <Button
                                        onClick={() => displayAssetHistory(asset.id)}
                                        className="mt-4"
                                        type="link"
                                        size="small"
                                      >
                                        View Repair History
                                      </Button>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                      <span className="asset-row title-row">
                                        <div>
                                          <Tooltip title="Asset No">
                                            <span className="title">{asset.assetTaggingNo}</span>
                                          </Tooltip>
                                          <Tag color={config.maintenanceRequestItemStatusLabels[item.status].color}>
                                            {config.maintenanceRequestItemStatusLabels[item.status].label}
                                          </Tag>
                                        </div>
                                        {isForARLApproval || isForRDOApproval || isForEngSupervisorApproval ? (
                                          <div className="actions">
                                            {(item.status === config.maintenanceRequestItemStatus.PENDING ||
                                              item.status === config.maintenanceRequestItemStatus.REJECTED) && (
                                                <Button
                                                  type="primary"
                                                  className="mr-2"
                                                  size="small"
                                                  onClick={() => {
                                                    const tmp: MaintenanceRequestHeaderType = { ...maintenanceRequest };
                                                    tmp.types[typeIndex].items[itemIndex].status =
                                                      config.maintenanceRequestItemStatus.APPROVED;
                                                    setMaintenanceRequest(tmp);
                                                  }}
                                                >
                                                  Approve
                                                </Button>
                                              )}
                                            {(item.status === config.maintenanceRequestItemStatus.PENDING ||
                                              item.status === config.maintenanceRequestItemStatus.APPROVED) && (
                                                <Button
                                                  type="link"
                                                  danger
                                                  size="small"
                                                  onClick={() => {
                                                    const tmp: MaintenanceRequestHeaderType = { ...maintenanceRequest };
                                                    tmp.types[typeIndex].items[itemIndex].status =
                                                      config.maintenanceRequestItemStatus.REJECTED;
                                                    setMaintenanceRequest(tmp);
                                                  }}
                                                >
                                                  Reject
                                                </Button>
                                              )}
                                          </div>
                                        ) : null}
                                      </span>
                                      <p className="asset-row">
                                        <Tooltip title="Classification">
                                          <span>
                                            <FaToolbox className="icon" />
                                            <span className="value">{asset.classification?.description}</span>
                                          </span>
                                        </Tooltip>
                                      </p>
                                      <p className="asset-row">
                                        <Tooltip title="Description/Brand Name">
                                          <span>
                                            <FaTrademark className="icon" />
                                            <span className="value">{asset.assetDescription}</span>
                                          </span>
                                        </Tooltip>
                                        <Tooltip title="Brand">
                                          <span className="value">{asset.brand}</span>
                                        </Tooltip>
                                      </p>
                                      <p className="asset-row">
                                        <Tooltip title="cost">
                                          <span>
                                            <FaMoneyBillWaveAlt className="icon" />
                                            <span className="value">N/A</span>
                                          </span>
                                        </Tooltip>
                                      </p>
                                    </div>
                                  </div>
                                  <FormSection className="p-4">
                                    <FormRow marginBottom="10">
                                      <div className="controls --inline">
                                        <label className="label">Classification:</label>
                                        <span className="value">
                                          {config.mrServiceRequestItemClassificationLabels[item.classification]}
                                        </span>
                                      </div>
                                    </FormRow>
                                    <FormRow marginBottom="10">
                                      <div className="controls">
                                        <label className="label">Description:</label>
                                        <p className="">{item.description}</p>
                                      </div>
                                    </FormRow>
                                    {item.approver1Remarks.length > 0 ? (
                                      <FormRow marginBottom="10">
                                        <div className="controls">
                                          <label className="label">ARL Remarks:</label>
                                          <p className="">{item.approver1Remarks}</p>
                                        </div>
                                      </FormRow>
                                    ) : null}
                                    {item.approver2Remarks.length > 0 ? (
                                      <FormRow marginBottom="10">
                                        <div className="controls">
                                          <label className="label">RDO Remarks:</label>
                                          <p className="">{item.approver2Remarks}</p>
                                        </div>
                                      </FormRow>
                                    ) : null}
                                    {item.approver3Remarks.length > 0 ? (
                                      <FormRow marginBottom="10">
                                        <div className="controls">
                                          <label className="label">Engineering Supervisor Remarks:</label>
                                          <p className="">{item.approver3Remarks}</p>
                                        </div>
                                      </FormRow>
                                    ) : null}
                                    {isForARLApproval && (
                                      <FormRow marginBottom="10">
                                        <div className="controls">
                                          <label className="label">Remarks:</label>
                                          <div className="">
                                            <Input.TextArea
                                              value={
                                                maintenanceRequest.types[typeIndex].items[itemIndex].approver1Remarks
                                              }
                                              onChange={e => {
                                                const tmp: MaintenanceRequestHeaderType = { ...maintenanceRequest };
                                                tmp.types[typeIndex].items[itemIndex].approver1Remarks = e.target.value;
                                                setMaintenanceRequest(tmp);
                                              }}
                                              rows={3}
                                            ></Input.TextArea>
                                            {errors[`types[${typeIndex}].items[${itemIndex}].approver1Remarks`] ? (
                                              <span className="error-message mt-2">
                                                {errors[`types[${typeIndex}].items[${itemIndex}].approver1Remarks`]}
                                              </span>
                                            ) : null}
                                          </div>
                                        </div>
                                      </FormRow>
                                    )}
                                    {isForRDOApproval && (
                                      <FormRow marginBottom="10">
                                        <div className="controls">
                                          <label className="label">Remarks:</label>
                                          <div className="">
                                            <Input.TextArea
                                              value={
                                                maintenanceRequest.types[typeIndex].items[itemIndex].approver2Remarks
                                              }
                                              onChange={e => {
                                                const tmp: MaintenanceRequestHeaderType = { ...maintenanceRequest };
                                                tmp.types[typeIndex].items[itemIndex].approver2Remarks = e.target.value;
                                                setMaintenanceRequest(tmp);
                                              }}
                                              rows={3}
                                            ></Input.TextArea>
                                            {errors[`types[${typeIndex}].items[${itemIndex}].approver2Remarks`] ? (
                                              <span className="error-message mt-2">
                                                {errors[`types[${typeIndex}].items[${itemIndex}].approver2Remarks`]}
                                              </span>
                                            ) : null}
                                          </div>
                                        </div>
                                      </FormRow>
                                    )}
                                    {isForEngSupervisorApproval && (
                                      <FormRow marginBottom="10">
                                        <div className="controls">
                                          <label className="label">Remarks:</label>
                                          <div className="">
                                            <Input.TextArea
                                              value={
                                                maintenanceRequest.types[typeIndex].items[itemIndex].approver3Remarks
                                              }
                                              onChange={e => {
                                                const tmp: MaintenanceRequestHeaderType = { ...maintenanceRequest };
                                                tmp.types[typeIndex].items[itemIndex].approver3Remarks = e.target.value;
                                                setMaintenanceRequest(tmp);
                                              }}
                                              rows={3}
                                            ></Input.TextArea>
                                            {errors[`types[${typeIndex}].items[${itemIndex}].approver3Remarks`] ? (
                                              <span className="error-message mt-2">
                                                {errors[`types[${typeIndex}].items[${itemIndex}].approver3Remarks`]}
                                              </span>
                                            ) : null}
                                          </div>
                                        </div>
                                      </FormRow>
                                    )}
                                  </FormSection>
                                </AssetItemRowWrapper>
                              );
                            })}
                          </div>
                        </CardBody>
                        {isForEngSupervisorApproval &&
                          type.status === config.maintenanceRequestTypeStatuses.RDO_APPROVED ? (
                          <div className="card-footer d-flex justify-content-end">
                            <Button
                              loading={submitFormLoading}
                              onClick={() => handleProcessServiceRequestByEngineeringSupervisor(type)}
                              type="primary"
                            >
                              Process Service Request {type.referenceNumber}
                            </Button>
                          </div>
                        ) : null}
                      </Card>
                    </Panel>
                  </Collapse>
                );
              })}
              {isForARLApproval || isForRDOApproval ? (
                <Card>
                  <div className="card-footer d-flex justify-content-end">
                    <Button loading={submitFormLoading} htmlType="submit" type="primary">
                      Process Request
                    </Button>
                  </div>
                </Card>
              ) : null}
            </div>
          )}
        </form>
      </Page>
    </DashboardLayout>
  );
});

export default index;
