import React, { useState, useEffect } from 'react';
import { Rate, List, Divider, Statistic, Skeleton, Select, DatePicker } from 'antd';
import moment from 'moment';
import accounting from 'accounting-js';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Page } from '../../components';
import { useMst } from '../../store/RootStore';
import { TotalRequestCountType, TotalRepairCostType, TechnicianRatingType } from '../../types';
import config from '../../config/config';
import classNames from "classnames";
import { Line } from "react-chartjs-2";
import Axios from 'axios';

// reactstrap components
import {
  Button,
  ButtonGroup,
  CardHeader,
  CardBody,
  CardTitle,
  Card,
  Row,
  Col,
} from "reactstrap";
import DashboardLayout from '../../layouts/Dashboard';

const Wrapper = styled.div``;

const Dashboard: React.FC = observer(() => {
  const store = useMst();
  const [storesList, setStoreList] = useState<any[]>([]);

  const [stores, setStores] = useState<any[]>([]);
  const [date, setDate] = useState(moment().format('YYYY-MM'));

  const [pendingRequestLoading, setPendingRequestLoading] = useState(false);
  const [pendingRequestsCount, setPendingRequestsCount] = useState<TotalRequestCountType | null>();

  const [forApprovalCountLoading, setForApprovalCountLoading] = useState(false);
  const [forApprovalCount, setForApprovalCount] = useState<TotalRequestCountType | null>();

  const [totalRepairCostLoading, setTotalRepairCostLoading] = useState(false);
  const [totalRepairCost, setTotalRepairCost] = useState<TotalRepairCostType | null>();

  const [technicianRatingsLoading, setTechnicianRatingsLoading] = useState(false);
  const [technicianRatings, setTechnicianRatings] = useState<TechnicianRatingType[]>([]);

  const chart1_2_options: any = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 30,
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
    },
  };

  const submitFilter = () => {
    setTotalRepairCostLoading(true);
    store.userAccount.getTotalRepairCosts(stores, date).then(response => {
      setTotalRepairCost(response.data.data);
      setTotalRepairCostLoading(false);
    });
  }

  const chartExample1: any = {
    data1: (canvas: any) => {
      const ctx = canvas.getContext("2d");

      const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      const gradientStroke2 = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      gradientStroke2.addColorStop(1, "rgba(218,104,255,0.2)");
      gradientStroke2.addColorStop(0.4, "rgba(240,70,102,0.0)");
      gradientStroke2.addColorStop(0, "rgba(255,48,0,0"); //blue colors

      return {
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
        datasets: [
          {
            label: "Property Transfer Request",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: pendingRequestsCount?.pr_details,
          },
          {
            label: "Maintenance Request",
            fill: true,
            backgroundColor: gradientStroke2,
            borderColor: "#f04666",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#f04666",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#f046661",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: pendingRequestsCount?.mr_details,
          },
        ]
      };
    },
    data2: (canvas: any) => {
      const ctx = canvas.getContext("2d");

      const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
        datasets: [
          {
            label: "My First dataset",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 15,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [1, 3, 4, 1, 5],
          },
        ],
      };
    },
    options: chart1_2_options,
    maintainAspectRatio: false,
  };

  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name: React.SetStateAction<string>) => {
    setbigChartData(name);
  };

  useEffect(() => {
    (async () => {
      const token = store.userAccount.token;
      const response = await Axios.get('/api/references/stores/list', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStoreList(response.data);

      if (response.data.length === 1) {
        setStores([response.data[0].id]);
      }
    })();
  }, []);

  useEffect(() => {
    setPendingRequestLoading(true);
    store.userAccount.getTotalPendingRequestsCount().then(response => {
      setPendingRequestsCount(response.data.data);
      setPendingRequestLoading(false);
    });
    setForApprovalCountLoading(true);
    store.userAccount.getTotalForApprovalCount().then(response => {
      setForApprovalCount(response.data.data);
      setForApprovalCountLoading(false);
    });
    setTotalRepairCostLoading(true);
    store.userAccount.getTotalRepairCosts().then(response => {
      setTotalRepairCost(response.data.data);
      setTotalRepairCostLoading(false);
    });
    setTechnicianRatingsLoading(true);
    store.userAccount.getTechnicianRatings().then(response => {
      setTechnicianRatings(response.data.data);
      setTechnicianRatingsLoading(false);
    });
  }, []);

  return (
    <DashboardLayout>
      <Page>
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              {pendingRequestLoading ? (
                <Skeleton />
              ) : (
                <>
                  <CardHeader >
                    <Row style={{ display: 'flex' }}>
                      <Col className="text-left" style={{ flex: 1 }} sm="6">
                        <h5 className="card-category">Pending Requests</h5>
                        <CardTitle tag="h2">{pendingRequestsCount?.totalRequest}</CardTitle>
                      </Col>
                      <Col sm="6" style={{ flex: 1 }}>
                        <ButtonGroup
                          className="btn-group-toggle float-right"
                          data-toggle="buttons"
                        >
                          <Button
                            tag="label"
                            className={classNames("btn-simple", {
                              active: bigChartData === "data1",
                            })}
                            color="info"
                            id="0"
                            size="sm"
                            onClick={() => setBgChartData("data1")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Month
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-single-02" />
                            </span>
                          </Button>
                          <Button
                            color="info"
                            id="1"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                              active: bigChartData === "data2",
                            })}
                            onClick={() => setBgChartData("data2")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Week
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-gift-2" />
                            </span>
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={chartExample1[bigChartData]}
                        options={chartExample1.options}

                      />
                    </div>
                  </CardBody>
                </>
              )}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            {store.userAccount.user?.role === config.roles.ENGINEERING_ADMIN ? (
              <Card>
                <div className="card-body">
                  {technicianRatingsLoading ? (
                    <Skeleton />
                  ) : (
                    <>
                      <label className="card-title">Technicians:</label>
                      <List
                        itemLayout="horizontal"
                        dataSource={technicianRatings}
                        renderItem={item => (
                          <List.Item>
                            {item.userAccount.user?.firstName} {item.userAccount.user?.lastName}{' '}
                            <Rate className="ml-3" value={item.rating} />
                          </List.Item>
                        )}
                      />
                    </>
                  )}
                </div>
              </Card>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            {store.userAccount.user?.role !== config.roles.STORES && (
              <>
                <div className="mb-3">
                  <label className="form-title mr-5">Stores:</label>
                  <Select style={{ minWidth: 300 }}
                    placeholder="Select store"
                    onChange={setStores}
                    value={stores}
                  >
                    <Select.Option value="">All Stores</Select.Option>
                    {storesList.map((store: any) => (
                      <Select.Option key={store.id} value={store.id}>
                        {store.label}
                      </Select.Option>
                    ))}
                  </Select>

                </div>
                <div className="mb-3">

                  <label className="form-title mr-5">Month:</label>
                  <DatePicker.MonthPicker
                    defaultValue={moment()}
                    disabledDate={(current) => current && current.valueOf() > Date.now()}
                    onChange={(_, dateString) => setDate(dateString || '')}
                    placeholder="Select month"
                  />

                  <Button className="ml-2 btn-sm" type="primary" onClick={submitFilter}>
                    Search
                  </Button>
                </div>
              </>
            )}

            {/* {store.userAccount.user?.role === config.roles.STORES ? ( */}
            <Card>
              <CardBody>
                <CardTitle className="font-weight-bold">Repair Costs for this month:</CardTitle>

                {totalRepairCostLoading ? (
                  <Skeleton />
                ) : (
                  <>
                    <div className="d-flex flex-column">
                      <div style={{ flex: 1 }} className="">
                        <Statistic
                          style={{ flex: 1 }}
                          title="Total"
                          value={accounting.formatMoney(totalRepairCost?.total)}
                        />
                      </div>
                      <Divider />
                      <div style={{ flex: 1 }} className="">
                        {totalRepairCost && Object.keys(totalRepairCost.byAsset).length > 0 ? (
                          <>
                            <label style={{ fontSize: 14, fontWeight: 'bold' }} className="d-block mb-3">
                              By Assets:
                            </label>
                            <List>
                              {Object.keys(totalRepairCost.byAsset).map((key: string) => (
                                <List.Item key={totalRepairCost.byAsset[key].asset.id}>
                                  <span>
                                    {totalRepairCost.byAsset[key].asset.assetTaggingNo} -{' '}
                                    {totalRepairCost.byAsset[key].asset.assetDescription}
                                  </span>
                                  <span>{accounting.formatMoney(totalRepairCost.byAsset[key].total)}</span>
                                </List.Item>
                              ))}
                            </List>
                          </>
                        ) : null}
                      </div>
                      <Divider />
                      <div style={{ flex: 1 }} className="">
                        {totalRepairCost && Object.keys(totalRepairCost.byClassification).length > 0 ? (
                          <>
                            <label style={{ fontSize: 14, fontWeight: 'bold' }} className="d-block mb-3">
                              By Classification:
                            </label>
                            <List>
                              {Object.keys(totalRepairCost.byClassification).map((key: string) => (
                                <List.Item key={totalRepairCost.byClassification[key].classification.id}>
                                  <span>{totalRepairCost.byClassification[key].classification.description}</span>
                                  <span>{accounting.formatMoney(totalRepairCost.byClassification[key].total)}</span>
                                </List.Item>
                              ))}
                            </List>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
            {/* ) : null} */}
          </Col>
        </Row>
      </Page>
    </DashboardLayout>
  );
});

export default Dashboard;