// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useMst } from "../store/RootStore";

const AuthenticatedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const store = useMst();
  const isAuthenticated = store.userAccount.token;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

AuthenticatedRoute.propTypes = {
  children: PropTypes.any,
};

export default AuthenticatedRoute;
