import React from 'react';
import { Space, Spin } from 'antd';

const Loader: React.FC = () => {
  return (
    <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
      Loading...
      <Spin />
    </Space>
  );
}

export default Loader;