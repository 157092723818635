import React, { useEffect } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { Divider, Rate, Input, InputNumber, Modal, DatePicker, Select } from 'antd';
import PropTypes from 'prop-types';
import { useForm, Controller, useFieldArray } from 'react-hook-form';

import { FormSection, FormRow } from '../../../components';
import { StoreApproveThirdPartyWorkFormData, StoreApproveThirdPartyModalProps } from '../../../types';

const schema = yup.object().shape({
  rating: yup
    .number()
    .required('Please rate the technician')
    .test('rating', 'Please rate the technician', value => value > 0),
  remarks: yup.string().required('Please leave a comment'),
});

const StoreApproveThirdPartyWorkModal: React.FC<StoreApproveThirdPartyModalProps> = ({
  visible,
  onSubmit,
  onCancel,
}) => {
  const { control, handleSubmit, errors, reset } = useForm<StoreApproveThirdPartyWorkFormData>({
    validationSchema: schema,
  });

  const onInnerSubmit = (values: StoreApproveThirdPartyWorkFormData) => {
    onSubmit && onSubmit(values);
  };

  return (
    <Modal
      className="white-content"
      title="Close Service Request"
      onOk={handleSubmit(onInnerSubmit)}
      onCancel={() => onCancel && onCancel()}
      visible={visible}
    >
      <p>
        You are about to close this service request. Please rate the technicians and leave a comment before you close
        the request.
      </p>
      <Divider />
      <FormSection>
        <FormRow className={errors.rating ? 'has-error' : ''}>
          <label className="form-title">Rate:</label>
          <Controller
            name="rating"
            control={control}
            as={<Rate tooltips={['terrible', 'bad', 'normal', 'good', 'wonderful']} />}
          />
          {errors.rating ? <span className="error-message mt-2">{errors.rating.message}</span> : null}
        </FormRow>
        <FormRow className={errors.remarks ? 'has-error' : ''}>
          <label className="form-title">Remarks:</label>
          <Controller name="remarks" control={control} as={<Input.TextArea rows={3} />} />
          {errors.remarks ? <span className="error-message mt-2">{errors.remarks.message}</span> : null}
        </FormRow>
        <Divider />
      </FormSection>
    </Modal>
  );
};

StoreApproveThirdPartyWorkModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default StoreApproveThirdPartyWorkModal;
