import React, { useState, useEffect } from "react";
import { Table, Select, Button, Input } from "antd";
import styled from "styled-components";
import { Card, CardHeader, CardBody } from "reactstrap";
import Axios from "axios";

import DashboardLayout from "../../layouts/Dashboard";
import { Page } from "../../components";
import { useMst } from "../../store/RootStore";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../components/Loader";

const Wrapper = styled.div``;

const AssetHistorySummary: React.FC = () => {
  const store = useMst();
  const location: any = useLocation();

  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);

  const [assetNumber, setAssetNumber] = useState<string>("");
  const [selectedStores, setSelectedStores] = useState<any[]>(
    location?.state?.selectedStores || []
  );

  const columns = [
    {
      title: "Store",
      dataIndex: "store_number",
      render: (store: string) => store,
    },
    {
      title: "Asset Number",
      dataIndex: "asset_number",
      render: (value: string) => (
        <Link
          to={{
            pathname: `/reports/asset_history/details/${value}`,
            state: { selectedStores },
          }}
        >
          {value}
        </Link>
      ),
    },
    {
      title: "Description",
      dataIndex: "asset_description",
      render: (value: string) => value,
    },
    {
      title: "Classification",
      dataIndex: "classification",
      render: (value: string) => value,
    },

    {
      title: "Brand",
      dataIndex: "brand",
      render: (value: string) => value,
    },
    {
      title: "Serial Number",
      dataIndex: "serial_no",
      render: (value: string) => value,
    },
    {
      title: "Model Number",
      dataIndex: "model_no",
      render: (value: string) => value,
    },
    {
      title: "Total Repair Cost",
      dataIndex: "total_repair_cost",
      render: (value: string) => parseFloat(value).toFixed(2),
    },
    {
      title: "Total Repair Count",
      dataIndex: "total_repair_count",
      render: (value: string) => parseInt(value, 10),
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      const token = store.userAccount.token;
      const response = await Axios.get("/api/references/stores/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setStores(response.data);

      if (response.data.length === 1) {
        setSelectedStores([response.data[0].id]);
      }
    })();

    if (location.state) {
      handleSearch();
    }
  }, []);

  const handleSelectStore = (values: any) => {
    console.log(values);
    setSelectedStores(values);
  };

  const handleSearch = async () => {
    setLoading(true);
    const token = store.userAccount.token;
    const response = await Axios.get("/api/reports/assets_history/summary", {
      params: {
        stores: selectedStores.join(","),
        asset_no: assetNumber,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("report", response);
    setLoading(false);
    setData(response.data.data);
  };

  const handleExport = async () => {
    const token = store.userAccount.token;
    const response = await Axios.get(
      "/api/reports/assets_history/summary/export",
      {
        responseType: "blob",
        params: {
          stores: selectedStores.join(","),
          asset_no: assetNumber,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "asset-history-summary.xls");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <DashboardLayout>
      <Page>
        <Card style={{ zIndex: 2 }}>
          <CardHeader>Asset History Summary</CardHeader>
          <CardBody>
            <Wrapper>
              <p className="card-title"></p>
              <div>
                <label className="form-title mr-5">Store:</label>
                <Select
                  style={{ minWidth: 300 }}
                  placeholder="Select store/s"
                  mode="multiple"
                  value={selectedStores}
                  onChange={handleSelectStore}
                >
                  {stores.map((store: any) => (
                    <Select.Option
                      key={store.id}
                      value={store.id}
                      disabled={
                        selectedStores.length >= 3
                          ? selectedStores.includes(store.id)
                            ? false
                            : true
                          : false
                      }
                    >
                      {store.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="mt-3">
                <label className="form-title mr-5">Asset Number:</label>
                <Input
                  style={{ width: 300 }}
                  onChange={(e) => setAssetNumber(e.target.value)}
                />
              </div>
              <div className="mt-3">
                <Button className="mr-5" type="primary" onClick={handleSearch}>
                  Search
                </Button>
                {data.length > 0 && (
                  <Button type="primary" onClick={handleExport}>
                    Export
                  </Button>
                )}
              </div>
            </Wrapper>
          </CardBody>
        </Card>
        <Card style={{ zIndex: 2 }}>
          <CardBody>
            <Wrapper>
              {loading ? (
                <Loader />
              ) : (
                <Table columns={columns} dataSource={data} size="small"></Table>
              )}
            </Wrapper>
          </CardBody>
        </Card>
      </Page>
    </DashboardLayout>
  );
};

export default AssetHistorySummary;
