// @ts-nocheck
import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useWindowWidth } from "@react-hook/window-size";

import { Sidebar, Header } from "../components/";
import { Drawer } from "antd";
import { useMst } from "../store/RootStore";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background: #fff;
  position: relative;

  @media (min-width: 1300px) {
    margin: 0 auto;
  }
`;

const Main = styled.div`
  flex: 1;
  margin-left: 230px;
  position: relative;

  @media (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
    width: 50%;
  }
`;

const Content = styled.div`
  padding: 20px;
  background-color: #f8f9fe;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ContentContainer = styled.div`
  margin-top: -115px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const DashboardLayout: React.FC = observer(({ children }) => {
  const store = useMst();
  const location = useLocation();
  const windowWidth = useWindowWidth();

  useEffect(() => {
    store.globalUI.setMenuDrawerVisible(false);
  }, [location.pathname]);

  return (
    <div className="white-content responsive">
      <Wrapper>
        <Main>
          <Header />
          <Content>
            <ContentContainer>{children}</ContentContainer>
          </Content>
        </Main>
        {windowWidth > 768 ? (
          <Sidebar />
        ) : (
          <Drawer
            placement="left"
            onClose={() => store.globalUI.setMenuDrawerVisible(false)}
            visible={store.globalUI.menuDrawerVisible}
          >
            <Sidebar />
          </Drawer>
        )}
      </Wrapper>
    </div>
  );
});

DashboardLayout.propTypes = {
  children: PropTypes.any,
};

export default DashboardLayout;
