import React, { useState, useEffect } from 'react';
import FileSaver from 'file-saver';
import { Button, Divider, Select, Table, Skeleton, notification } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import DashboardLayout from '../../../layouts/Dashboard';
import { Page, Card } from '../../../components';
import { UserType, MaintenanceRequestHeaderType, MaintenanceRequestTypeModelType } from '../../../types';
import { useMst } from '../../../store/RootStore';
import config from '../../../config/config';
import { PaginationProps, PaginationConfig } from 'antd/lib/pagination';
import UserFilter from '../../../components/UserFilter';

const columns = [
  {
    title: 'Store',
    dataIndex: 'user',
    render: (user: UserType) => `${user.firstName} ${user.lastName}`,
  },
  {
    title: 'Reference number',
    dataIndex: 'referenceNumber',
    render: (referenceNumber: string) => referenceNumber,
  },
  {
    title: 'Requestor Name',
    dataIndex: 'requestorName',
    render: (requestorName: string) => requestorName,
  },
  {
    title: 'Total Services',
    dataIndex: 'types',
    render: (types: MaintenanceRequestTypeModelType[]) => (types ? types.length : 0),
  },
  {
    title: 'No. of items',
    dataIndex: 'types',
    render: (types: MaintenanceRequestTypeModelType[]) => {
      return types ? types.map(type => type.items.length).reduce((p, c) => p + c, 0) : 0;
    },
  },
  {
    title: 'Date applied',
    dataIndex: 'createdAt',
    render: (date: string) => {
      return moment(date).format('YYYY-MM-DD');
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: number) => config.maintenanceRequestStatusLabels[status],
  },
  {
    title: 'Actions',
    dataIndex: 'uuid',
    render: (value: string) => <Link to={`/maintenance_requests/${value}`}>View</Link>, // eslint-disable-line
  },
];

const Wrapper = styled.div``;

const AllMaintenanceRequests: React.FC = observer(() => {
  const store = useMst();
  const references = store.references;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MaintenanceRequestHeaderType[]>([]);
  const [pagination, setPagination] = useState<PaginationProps>({});
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const [searchUserText, setSearchUserText] = useState<string | null>(null);
  const [selectedUserText, setSelectedUserText] = useState<string | null>(null);

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const params = {
        page,
        user: selectedUser,
        status: selectedStatus,
      };
      const response = await store.userAccount.getAllMaintenanceRequests(params);

      setPagination({
        pageSize: config.pageSize,
        current: page,
        total: response.data.meta.total,
      });

      setData(response.data.data);
      setLoading(false);
    } catch (e) {
      notification['error']({
        message: 'My Maintenance Requests',
        description: 'An error occurred while fetching your data. Please try again later.',
      });
    }
  };

  const filterTable = async () => {
    await fetchData(pagination.current);
  };

  const handleChange = async (pagination: PaginationConfig) => {
    await fetchData(pagination.current);
  };

  const exportData = async () => {
    const response = await store.userAccount.exportMaintenanceRequests(selectedUser, selectedStatus);

    const blob = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    });
    FileSaver.saveAs(blob, 'maintenance_requests.xls');
  };

  useEffect(() => {
    fetchData().then(() => {
      console.log('data loaded.');
    });
  }, []);

  return (
    <DashboardLayout>
      <Page>
        <Card style={{ zIndex: 2 }}>
          <div className="card-body">
            <Wrapper>
              <p className="card-title">My Maintenance Requests</p>
              <div>
                <div className="filters">
                  <p className="title">Filters:</p>
                  <UserFilter
                    value={[selectedUser, selectedUserText]}
                    searchText={searchUserText}
                    onSearch={value => setSearchUserText(value)}
                    onChange={(value: string, text: string) => {
                      setSelectedUser(value)
                      setSelectedUserText(text)
                    }} />
                  <Select
                    allowClear={true}
                    className="mr-2"
                    style={{ width: 200 }}
                    placeholder="Filter by Status"
                    value={selectedStatus ?? undefined}
                    onChange={(value: string) => setSelectedStatus(value)}
                  >
                    <Select.Option value="0">For ARL Approval</Select.Option>
                    <Select.Option value="5">FOR RDO Approval</Select.Option>
                    <Select.Option value="7">For Eng Supervisor Approval</Select.Option>
                    <Select.Option value="8">Work In Progress</Select.Option>
                    <Select.Option value="10">Work Done</Select.Option>
                    <Select.Option value="-1">Rejected</Select.Option>
                    <Select.Option value="-2">Cancelled</Select.Option>
                  </Select>
                  <Button onClick={filterTable} type="primary" className="mr-2">
                    Filter table
                  </Button>
                  <Button onClick={exportData} type="primary">
                    Export Data
                  </Button>
                </div>
                <Divider />
                {loading ? (
                  <Skeleton />
                ) : (
                  <Table
                    columns={columns}
                    pagination={pagination}
                    onChange={handleChange}
                    dataSource={data}
                    size="small"
                  ></Table>
                )}
              </div>
            </Wrapper>
          </div>
        </Card>
      </Page>
    </DashboardLayout>
  );
});

export default AllMaintenanceRequests;
