import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import config from '../config/config';
import { useMst } from '../store/RootStore';

import { UserType } from '../types';

type ComponentProps = {
  value?: [Number, string] | null | unknown,
  searchText?: string | null,
  onSearch: (text: string) => void,
  onChange: ((id: string, text: string) => void)
};

const UserFilter: React.FC<ComponentProps> = ({ value, searchText, onSearch, onChange }: ComponentProps) => {
  const store = useMst();

  // states
  const [normalUsers, setNormalUsers] = useState<UserType[]>([]);
  const [engineeringUsers, setEngineeringUsers] = useState<UserType[]>([]);
  const [storeUsers, setStoreUsers] = useState<UserType[]>([]);

  // map users
  const getMappedUsers = users => users.map(user => ({
    id: user.id,
    fullName: user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName,
    email: user.email,
  }));

  const getNormalUsers = users => getMappedUsers(users.filter((user: UserType) => user.role === config.roles.NORMAL_USERS));
  const getEngineeringUsers = users => getMappedUsers(users.filter((user: UserType) => user.role === config.roles.ENGINEERING || user.role === config.roles.ENGINEERING_ADMIN));
  const getStoreUsers = users => getMappedUsers(users.filter((user: UserType) => user.role === config.roles.STORES));

  const onSearchHandler = async (newValue: string) => {
    if (newValue) {
      const response = await store.references.searchUsers(newValue);
      const users = response.data.data;

      onSearch(newValue);
      setNormalUsers(getNormalUsers(users));
      setEngineeringUsers(getEngineeringUsers(users));
      setStoreUsers(getStoreUsers(users));
    } else {
      setNormalUsers([]);
      setEngineeringUsers([]);
      setStoreUsers([]);
    }
  };

  const renderUsers = (label, data) => {
    if (data.length === 0) {
      return null;
    }

    return (
      <Select.OptGroup label={label}>
        {data.map((user: UserType) => (
          <Select.Option key={user.id} value={user.id}>
            {user.fullName} ({user.email})
          </Select.Option>
        ))}
      </Select.OptGroup>
    );
  }

  return (
    <Select
      onClick={() => onSearchHandler(searchText ?? '')}
      showSearch
      allowClear={true}
      optionFilterProp="children"
      style={{ minWidth: 300 }}
      className="mr-2"
      value={value && value[0] ? value : null}
      onChange={(val: string, opts) => {
        onChange(val, opts?.children?.join('') ?? '');
        if (val === undefined) {
          onSearch('');
        }
        return [val, opts];
      }}
      onSearch={onSearchHandler}
      placeholder="Start typing to search users..."
    >
      {renderUsers('Users', normalUsers)}
      {renderUsers('Engineering', engineeringUsers)}
      {renderUsers('Store', storeUsers)}
    </Select>
  );
}

export default UserFilter;