import React from 'react';
import { Menu, Layout } from 'antd';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { TiArchive, TiDropbox, TiTicket, TiHome, TiInputChecked, TiArrowForward, TiSpanner } from 'react-icons/ti';
import { useMst } from '../store/RootStore';

const Wrapper = styled.div`
  position: fixed;
  min-height: 100vh;

  /* font-weight: bold; */

  .ant-menu-submenu-title {
    color: #000;
  }

  .ant-layout-sider {
    background: transparent;
  }
  .ant-menu-item:hover,
  .ant-menu-item.ant-menu-item-active {
    color: #000;
    background: transparent;
    font-weight: bold;
  }
  .ant-menu-dark,
  .ant-menu-dark .ant-menu-sub {
    background: transparent;
  }
  .ant-menu-dark .ant-menu-item,
  .ant-menu-dark .ant-menu-item-group-title,
  .ant-menu-dark .ant-menu-item > a {
    color: #000;
  }
  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    color: #000;
    background: transparent;
    font-weight: bold;
  }
  .ant-menu-submenu .ant-menu-submenu-title {
    color: #000;
  }

  .ant-menu-submenu:hover .ant-menu-submenu-title,
  .ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title {
    font-weight: bold;
  }

  .icon {
    /* margin-top: 10px; */
    margin-right: 10px;
    font-size: 18px;
  }

  .link-wrapper {
    display: flex;
    align-items: center;
  }

  .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-arrow::after {
    background: #000 !important;
  }

  @media (max-width: 768px) {
    /* display: none; */
  }
`;

const Logo = styled.div`
  color: #000;
  padding: 10px 20px;
  font-size: 32px;
  margin-bottom: 20px;
`;

export const Sidebar = observer(() => {
  const store = useMst();
  const { location } = useHistory();
  const path = location.pathname.replace('/', '');

  const openKey = path.split('/')[0];
  const access = store.userAccount.access;

  return (
    <Wrapper>
      <Layout.Sider width={230}>
        <Logo>
          <span>R&M</span>
        </Logo>
        <Menu mode="inline" defaultOpenKeys={[openKey]} selectedKeys={[path]}>
          <Menu.Item key="dashboard">
            <Link to="/">
              <div className="link-wrapper">
                <TiHome className="icon" />
                <span>Dashboard</span>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="assets">
            <Link to="/assets">
              <div className="link-wrapper">
                <TiDropbox className="icon" />
                <span>Assets</span>
              </div>
            </Link>
          </Menu.Item>
          {access?.approvals.any ? (
            <Menu.SubMenu
              key="approvals"
              title={
                <div className="link-wrapper">
                  <TiInputChecked className="icon" />
                  <span>My approvals</span>
                </div>
              }
            >
              {access?.approvals.propertyTransfer ? (
                <Menu.Item key="approvals/property_transfer_requests">
                  <Link to="/approvals/property_transfer_requests" replace>
                    <div className="link-wrapper">
                      <span>Property Transfers</span>
                    </div>
                  </Link>
                </Menu.Item>
              ) : null}
              {access?.approvals.maintenanceRequest ? (
                <Menu.Item key="approvals/maintenance_requests/pending">
                  <Link to="/approvals/maintenance_requests/pending" replace>
                    <div className="link-wrapper">
                      <span>Maintenance Requests</span>
                    </div>
                  </Link>
                </Menu.Item>
              ) : null}
              {access?.approvals.work ? (
                <Menu.Item key="approvals/work_management">
                  <Link to="/approvals/work_management" replace>
                    <div className="link-wrapper">
                      <span>Work Management</span>
                    </div>
                  </Link>
                </Menu.Item>
              ) : null}
              {access?.approvals.cost ? (
                <Menu.Item key="approvals/costs">
                  <Link to="/approvals/costs" replace>
                    <div className="link-wrapper">
                      <span>Costs</span>
                    </div>
                  </Link>
                </Menu.Item>
              ) : null}
            </Menu.SubMenu>
          ) : null}

          <Menu.SubMenu
            key="submenu_property_transfers"
            title={
              <div className="link-wrapper">
                <TiArrowForward className="icon" />
                <span>Property Transfer</span>
              </div>
            }
          >
            <Menu.Item key="property_transfers">
              <Link to="/property_transfers" replace>
                <div className="link-wrapper">
                  <span>My Requests</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="property_transfers/create">
              <Link to="/property_transfers/create" replace>
                <div className="link-wrapper">
                  <span>Create Request</span>
                </div>
              </Link>
            </Menu.Item>
          </Menu.SubMenu>

          {access?.maintenanceRequest ? (
            <Menu.SubMenu
              key="submenu_maintenance_requests"
              title={
                <div className="link-wrapper">
                  <TiSpanner className="icon" />
                  <span>Maintenance Request</span>
                </div>
              }
            >
              <Menu.Item key="maintenance_requests">
                <Link to="/maintenance_requests" replace>
                  <div className="link-wrapper">
                    <span>My Requests</span>
                  </div>
                </Link>
              </Menu.Item>
              <Menu.Item key="maintenance_requests/create">
                <Link to="/maintenance_requests/create" replace>
                  <div className="link-wrapper">
                    <span>Create Request</span>
                  </div>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          ) : null}

          <Menu.SubMenu
            key="submenu_reports"
            title={
              <div className="link-wrapper">
                <TiSpanner className="icon" />
                <span>Reports</span>
              </div>
            }
          >
            <Menu.Item key="reports">
              <Link to="/reports/repair_summary" replace>
                <div className="link-wrapper">
                  <span>Repair Summary</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="reports/asset_history">
              <Link to="/reports/asset_history" replace>
                <div className="link-wrapper">
                  <span>Asset History</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="reports/property_transfers">
              <Link to="/reports/property_transfers" replace>
                <div className="link-wrapper">
                  <span>PTAF Summary</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="reports/cost_summary">
              <Link to="/reports/cost_summary" replace>
                <div className="link-wrapper">
                  <span>Cost Summary</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="reports/cost_summary_count">
              <Link to="/reports/cost_summary_count" replace>
                <div className="link-wrapper">
                  <span>Cost Summary Count</span>
                </div>
              </Link>
            </Menu.Item>
          </Menu.SubMenu>

          {access?.work ? (
            <Menu.SubMenu
              key="/work_management"
              title={
                <div className="link-wrapper">
                  <TiTicket className="icon" />
                  <span>Work Management</span>
                </div>
              }
            >
              <Menu.Item key="work_management/in_progress">
                <Link to="/work_management/in_progress" replace>
                  <div className="link-wrapper">
                    <span>In-Progress</span>
                  </div>
                </Link>
              </Menu.Item>
              <Menu.Item key="work_management/for_final_costing">
                <Link to="/work_management/for_final_costing" replace>
                  <div className="link-wrapper">
                    <span>For final costing</span>
                  </div>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          ) : null}

          {access?.admin.propertyTransfer || access?.admin.maintenanceRequest ? (
            <Menu.SubMenu
              key="/admin"
              title={
                <div className="link-wrapper">
                  <TiArchive className="icon" />
                  <span>Admin</span>
                </div>
              }
            >
              {access?.admin.propertyTransfer ? (
                <Menu.SubMenu
                  key="/admin/property_transfer_requests"
                  title={
                    <div className="link-wrapper">
                      <span>Property Transfer</span>
                    </div>
                  }
                >
                  <Menu.Item key="admin/property_transfer_requests">
                    <Link to="/admin/property_transfer_requests" replace>
                      <div className="link-wrapper">
                        <span>All requests</span>
                      </div>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="admin/property_transfer_requests/create">
                    <Link to="/admin/property_transfer_requests/create" replace>
                      <div className="link-wrapper">
                        <span>Create request</span>
                      </div>
                    </Link>
                  </Menu.Item>
                </Menu.SubMenu>
              ) : null}
              {access?.admin.maintenanceRequest ? (
                <Menu.SubMenu
                  key="/admin/maintenance_requests"
                  title={
                    <div className="link-wrapper">
                      <span>Maintenance Requests</span>
                    </div>
                  }
                >
                  <Menu.Item key="admin/maintenance_requests">
                    <Link to="/admin/maintenance_requests" replace>
                      <div className="link-wrapper">
                        <span>All requests headers</span>
                      </div>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="admin/maintenance_request_types">
                    <Link to="/admin/maintenance_request_types" replace>
                      <div className="link-wrapper">
                        <span>All service requests</span>
                      </div>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="admin/maintenance_requests/create">
                    <Link to="/admin/maintenance_requests/create" replace>
                      <div className="link-wrapper">
                        <span>Create request</span>
                      </div>
                    </Link>
                  </Menu.Item>
                </Menu.SubMenu>
              ) : null}
            </Menu.SubMenu>
          ) : null}
        </Menu>
      </Layout.Sider>
    </Wrapper>
  );
});
