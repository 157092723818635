import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Popconfirm, Table, Skeleton, Button, Modal, Breadcrumb, notification, Input } from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';

import { useMst } from '../../store/RootStore';
import DashboardLayout from '../../layouts/Dashboard';
import { ListWrapper, TableWrapper, ModalWrapper, Wrapper } from './styles';
import { AssetType, UserType, PropertyTransferRequest, PropertyTransferRequestItem } from '../../types';
import { PropertyTransferItemList, HorizontalLine, FormSection, FormRow, Page, Card } from '../../components';
import { PropertyTransferItemTableColumns } from './common';
import config from '../../config/config';

const index: React.FC = observer(() => {
  const store = useMst();
  const history = useHistory();
  const { id } = useParams();

  const [readonly, setReadonly] = useState(true);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [propertyTransferRequest, setPropertyTransferRequest] = useState<null | PropertyTransferRequest>(null);
  const [displayedItems, setDisplayedItems] = useState<PropertyTransferRequestItem[]>([]);

  useEffect(() => {
    // load request details here
    store.userAccount
      .getPropertyTransferRequest(id)
      .then(response => {
        const data = response.data.data;
        setPropertyTransferRequest(data);
        setDisplayedItems(data.items);

        if (store.userAccount.user) {
          // check request is still pending and if current user is approver
          if (
            data.status === config.propertyTransferRequestStatus.PENDING &&
            store.userAccount.user.id == data.userApproverMap?.ptafApprover.id
          ) {
            setReadonly(false);
          }
          if (
            data.status === config.propertyTransferRequestStatus.APPROVED_BY_APPROVER1 &&
            store.userAccount.user.id == data.userApproverMap?.ptafApprover2.id
          ) {
            setReadonly(false);
          }
          if (
            data.status === config.propertyTransferRequestStatus.APPROVED_BY_APPROVER2 &&
            store.userAccount.user.id == data.userApproverMap?.ptafApprover3.id
          ) {
            setReadonly(false);
          }
        }
        setLoading(false);
      })
      .catch(error => {
        notification['error']({
          message: 'Property Transfer Request',
          description: 'Request does not exist',
        });
        history.push('/property_transfers');
      });
  }, []);

  const handleItemChanged = (item: PropertyTransferRequestItem) => {
    if (propertyTransferRequest) {
      const items =
        propertyTransferRequest && propertyTransferRequest.items
          ? propertyTransferRequest.items.map(i => (item.asset.assetId === i.asset.assetId ? item : i))
          : [];
      const t: PropertyTransferRequest = { ...propertyTransferRequest };
      t.items = items;
      setPropertyTransferRequest(t);
      setDisplayedItems(items);
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    if (store.userAccount.user) {
      if (value.trim().length) {
      } else {
      }
    }
  };

  const handleShowReview = () => {
    // validate all items
    const pending = displayedItems.filter(item => item.status === 0);
    if (pending.length) {
      notification['error']({
        message: 'Property Transfer Approval',
        description: 'You must explicitly approve or reject all items',
      });
      return;
    }

    setReviewModalVisible(true);
  };

  const onSubmit = async () => {
    try {
      setHasSubmitted(true);

      // validate remarks
      const rejectedPropertyTransferItemsWithoutRemarks = propertyTransferRequest
        ? propertyTransferRequest.items.filter(
          item => item.status === config.propertyTransferItemStatus.REJECTED && item.remarks.trim().length === 0,
        )
        : [];

      if (rejectedPropertyTransferItemsWithoutRemarks.length) {
        return;
      }

      setSubmitLoading(true);
      const response = await store.userAccount.processPropertyTransferRequest(propertyTransferRequest);
      notification['success']({
        message: 'Property Transfer Request',
        description: 'You have successfully processed the property transfer request',
      });
      setSubmitLoading(false);
      history.push(`/approvals/property_transfer_requests`);
    } catch (error) {
      setSubmitLoading(false);
      notification['error']({
        message: 'Property Transfer Request',
        description: 'An error occurred while processing the request, try again later.',
      });
    }
  };

  const handleCancelRequest = async () => {
    try {
      const response = await store.userAccount.cancelPropertyTransferRequest(propertyTransferRequest);
      const updated = response.data.data;
      setPropertyTransferRequest(updated);
      notification['success']({
        message: 'Property Transfer Request',
        description: 'You have cancelled this request.',
      });
    } catch (e) {
      notification['error']({
        message: 'Property Transfer Request',
        description: 'An error occurred while processing the request, try again later.',
      });
    }
  };

  const approvedAssets = propertyTransferRequest
    ? propertyTransferRequest.items
      .filter(item => item.status === config.propertyTransferItemStatus.APPROVED)
      .map(item => item.asset)
    : [];

  const rejectedPropertyTransferItems = propertyTransferRequest
    ? propertyTransferRequest.items.filter(item => item.status === config.propertyTransferItemStatus.REJECTED)
    : [];

  return (
    <DashboardLayout>
      <Page>
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item>
            <Link to="/property_transfers">Property Transfer Requests</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Item</Breadcrumb.Item>
        </Breadcrumb>
        {loading ? (
          <>
            <Card>
              <div className="card-body">
                <Skeleton active />
                <Skeleton active />
              </div>
            </Card>
          </>
        ) : (
          <Wrapper>
            <Card>
              <div className="card-body">
                <div className="card-title d-flex justify-content-between">
                  <span>Property transfer request details</span>
                  {store.userAccount.user?.id === propertyTransferRequest?.user.id &&
                    propertyTransferRequest?.status === config.propertyTransferRequestStatus.PENDING ? (
                    <Popconfirm
                      title="Are you sure you want to cancel this request?"
                      onConfirm={() => handleCancelRequest()}
                    >
                      <Button type="primary" size="small" danger>
                        Cancel Request
                      </Button>
                    </Popconfirm>
                  ) : null}
                </div>
                <FormSection>
                  <FormRow marginBottom="10">
                    <div className="controls --inline">
                      <label className="label">Transfer type:</label>
                      <span className="value">
                        {propertyTransferRequest ? config.ptafTypes[propertyTransferRequest.transfertype] : null}
                      </span>
                    </div>
                  </FormRow>
                  <FormRow marginBottom="10">
                    <div className="controls --inline">
                      <label className="label">Transfer from:</label>
                      <span className="value">
                        {propertyTransferRequest?.user.firstName} {propertyTransferRequest?.user.lastName} - (
                        {propertyTransferRequest?.user.email})
                      </span>
                    </div>
                  </FormRow>
                  <FormRow marginBottom="10">
                    <div className="controls --inline">
                      <label className="label">Transfer to:</label>
                      <span className="value">
                        {propertyTransferRequest?.recipient.firstName} {propertyTransferRequest?.recipient.lastName} - (
                        {propertyTransferRequest?.recipient.email})
                      </span>
                    </div>
                  </FormRow>
                  <FormRow marginBottom="10">
                    <div className="controls --inline">
                      <label className="label">Remarks Field:</label>
                      <span className="value">{propertyTransferRequest?.remarksField}</span>
                    </div>
                  </FormRow>
                  <FormRow marginBottom="10">
                    <div className="controls --inline">
                      <label className="label">PTAF Number:</label>
                      <span className="value">{propertyTransferRequest?.ptafNumber}</span>
                    </div>
                  </FormRow>
                  <FormRow marginBottom="10">
                    <div className="controls --inline">
                      <label className="label">Date Issued:</label>
                      <span className="value">{moment(propertyTransferRequest?.dateIssued).format('YYYY-MM-DD')}</span>
                    </div>
                  </FormRow>
                  <FormRow marginBottom="10">
                    <div className="controls --inline">
                      <label className="label">Status:</label>
                      <span className="value">
                        {propertyTransferRequest
                          ? config.propertyTransferRequestStatusReference[propertyTransferRequest.status].label
                          : null}
                      </span>
                    </div>
                  </FormRow>
                </FormSection>
              </div>
            </Card>
            <Card>
              <div className="card-body">
                <div className="card-title">Assets to transfer</div>
                <div className="items-section">
                  <div className="items-list">
                    <PropertyTransferItemList
                      readonly={readonly}
                      items={displayedItems}
                      onChange={data => handleItemChanged(data)}
                    />
                  </div>
                </div>
              </div>
              {!readonly && (
                <div className="card-footer d-flex justify-content-end">
                  <Button type="primary" onClick={handleShowReview}>
                    Submit & Review
                  </Button>
                </div>
              )}
            </Card>
          </Wrapper>
        )}
        <Modal
          className="white-content"
          footer={null}
          title="Review Property Transfer details"
          width={800}
          onCancel={() => setReviewModalVisible(false)}
          visible={reviewModalVisible}
        >
          {propertyTransferRequest ? (
            <ModalWrapper>
              <TableWrapper>
                <div className="mb-3">
                  <div className="form-row">
                    <label className="label">Type:&nbsp;</label>
                    <span className="value">{config.ptafTypes[propertyTransferRequest.transfertype]}</span>
                  </div>
                  <div className="form-row">
                    <label className="form-title">Transfer from: </label>
                    <span className="value">
                      {propertyTransferRequest?.user.firstName} {propertyTransferRequest?.user.lastName}
                    </span>
                  </div>
                  <div className="form-row">
                    <label className="form-title">Transfer from: </label>
                    <span className="value">
                      {propertyTransferRequest?.recipient.firstName} {propertyTransferRequest?.recipient.lastName}
                    </span>
                  </div>
                </div>
              </TableWrapper>

              {approvedAssets.length ? (
                <TableWrapper>
                  <p className="label">Assets to transfer:</p>
                  <Table
                    size="small"
                    dataSource={approvedAssets}
                    columns={PropertyTransferItemTableColumns}
                    pagination={false}
                  />
                </TableWrapper>
              ) : null}

              {rejectedPropertyTransferItems.length ? (
                <ListWrapper>
                  <div className="label mb-2">Rejected assets:</div>
                  <div className="items">
                    {rejectedPropertyTransferItems.map(item => {
                      return (
                        <div key={item.asset.assetId} className="item">
                          <div>
                            <span className="label">Asset Tagging No: </span>
                            <span className="value">{item.asset.assetTaggingNo}</span>
                          </div>
                          <div>
                            <span className="label">Asset Description: </span>
                            <span className="value">{item.asset.assetDescription}</span>
                          </div>
                          <div className="mt-3">
                            <Input.TextArea
                              rows={3}
                              value={item.remarks}
                              onChange={(e): void => handleItemChanged({ ...item, remarks: e.target.value })}
                              placeholder="Please enter the reason why you rejected the transfer of this asset"
                            ></Input.TextArea>
                            {item.remarks.trim().length === 0 ? (
                              <span className="error-message mt-2">Remarks is required</span>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </ListWrapper>
              ) : null}

              <Button loading={submitLoading} size="large" type="primary" className="mt-5" onClick={() => onSubmit()}>
                Proceed
              </Button>
            </ModalWrapper>
          ) : null}
        </Modal>
      </Page>
    </DashboardLayout>
  );
});

export default index;
