import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Table, Select, Button } from "antd";
import styled from "styled-components";
import { DatePicker } from "antd";
import { Card, CardHeader, CardBody } from "reactstrap";
import Axios from "axios";
import moment from "moment";

import { Page } from "../../components";
import { useMst } from "../../store/RootStore";
import DashboardLayout from "../../layouts/Dashboard";
import Loader from "../../components/Loader";

const { RangePicker } = DatePicker;

const Wrapper = styled.div``;

const RepairSummary: React.FC = () => {
  const location = useLocation();
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);

  const [selectedClassification, setSelectedClassification] = useState<
    string | null
  >(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [selectedStores, setSelectedStores] = useState<any[]>(
    location.state?.selectedStores ?? []
  );
  const [from, setFrom] = useState<string | null>(location.state?.from ?? null);
  const [to, setTo] = useState<string | null>(location.state?.to ?? null);

  const columns = [
    {
      title: "Store",
      dataIndex: "store_number",
      render: (store: string) => store,
    },
    {
      title: "MR Number",
      dataIndex: "mr_number",
      render: (mrNumber: string, row: any) => (
        <Link
          to={{
            pathname: `/maintenance_requests/${row.uuid}`,
            state: {
              fromRepairsSummaryReport: true,
              selectedStores,
              from,
              to,
            },
          }}
        >
          {mrNumber}
        </Link>
      ),
    },
    // {
    //   title: 'Date',
    //   dataIndex: 'date',
    //   render: (date: string) => date,
    // },
    {
      title: "Asset Number",
      dataIndex: "asset_number",
      render: (value: string) => value,
    },
    {
      title: "Classification",
      dataIndex: "classification",
      render: (value: string) => value,
    },
    {
      title: "Total Repair Cost",
      dataIndex: "total_repair_cost",
      render: (value: string) => value,
    },
    {
      title: "Date Creation of Request",
      dataIndex: "created_at",
      render: (value: string) => value,
    },
    {
      title: "Asset Classification",
      dataIndex: "asset_classification",
      render: (value: string) => value,
    },
    {
      title: "Asset No.",
      dataIndex: "asset_tagging_no",
      render: (value: string) => value,
    },
    {
      title: "Details of Work Done",
      dataIndex: "description",
      render: (value: string) => value,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value: string) => value,
    },
  ];

  useEffect(() => {
    if (location.state) {
      handleSearch();
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const token = store.userAccount.token;
      const response = await Axios.get("/api/references/stores/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setStores(response.data);

      if (response.data.length === 1) {
        setSelectedStores([response.data[0].id]);
      }
    })();
  }, []);

  const handleSelectStore = (values: any) => {
    const lastSelected = values[values.length - 1];

    if (lastSelected === "all") {
      setSelectedStores(["all"]);
    } else {
      setSelectedStores(values.filter((el) => el !== "all"));
    }
  };

  const handleDateRangeChange = (values: any, valuesStr: any) => {
    if (selectedStores.includes("all")) {
      const startOfMonth = moment(values).startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment(values).endOf("month").format("YYYY-MM-DD");

      setFrom(startOfMonth);
      setTo(endOfMonth);
    } else {
      setFrom(valuesStr[0]);
      setTo(valuesStr[1]);
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    const token = store.userAccount.token;
    const response = await Axios.get("/api/reports/repairs/summary", {
      params: {
        status: selectedStatus,
        stores: selectedStores.join(","),
        from,
        to,
        classification: selectedClassification,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setLoading(false);
    setData(response.data.data);
  };

  const handleExport = async () => {
    const token = store.userAccount.token;
    const response = await Axios.get("/api/reports/repairs/summary/export", {
      responseType: "blob",
      params: {
        status: selectedStatus,
        stores: selectedStores.join(","),
        from,
        to,
        classification: selectedClassification,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report-summary.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <DashboardLayout>
      <Page>
        <Card>
          <CardHeader>Repair Summary</CardHeader>
          <CardBody>
            <Wrapper>
              <p className="title">Filters:</p>
              <div>
                <Select
                  style={{ minWidth: 300 }}
                  className="mr-2 mt-3"
                  placeholder="Select store/s"
                  mode="multiple"
                  value={selectedStores}
                  onChange={handleSelectStore}
                >
                  <Select.Option value="all">All Stores</Select.Option>
                  {stores.map((store: any) => (
                    <Select.Option
                      key={store.id}
                      value={store.id}
                      disabled={
                        selectedStores.length >= 3
                          ? selectedStores.includes(store.id)
                            ? false
                            : true
                          : false
                      }
                    >
                      {store.label}
                    </Select.Option>
                  ))}
                </Select>
                {selectedStores.includes("all") ? (
                  <DatePicker picker="month" onChange={handleDateRangeChange} />
                ) : (
                  <RangePicker
                    className="mr-3 mt-3"
                    onChange={handleDateRangeChange}
                  />
                )}
              </div>
              <div>
                <Select
                  allowClear={true}
                  className="mr-2 mt-3"
                  style={{ width: 200 }}
                  placeholder="Filter by Status"
                  value={selectedStatus ?? undefined}
                  onChange={(value: string) => setSelectedStatus(value)}
                >
                  <Select.Option value="0">For ARL Approval</Select.Option>
                  <Select.Option value="5">FOR RDO Approval</Select.Option>
                  <Select.Option value="7">
                    For Eng Supervisor Approval
                  </Select.Option>
                  <Select.Option value="8">Work In Progress</Select.Option>
                  <Select.Option value="10">Work Done</Select.Option>
                  <Select.Option value="-1">Rejected</Select.Option>
                  <Select.Option value="-2">Cancelled</Select.Option>
                </Select>

                <Select
                  allowClear={true}
                  className="mr-2 mt-3"
                  style={{ width: 200 }}
                  placeholder="Filter by Classification"
                  value={selectedClassification ?? undefined}
                  onChange={(value: string) => setSelectedClassification(value)}
                >
                  <Select.Option value="0">Repair</Select.Option>
                  <Select.Option value="1">
                    Preventive Maintenance
                  </Select.Option>
                  <Select.Option value="2">Regular Maintenance</Select.Option>
                </Select>
              </div>
              <div className="mt-3">
                <Button className="mr-5" type="primary" onClick={handleSearch}>
                  Search
                </Button>
                {data.length > 0 && (
                  <Button type="primary" onClick={handleExport}>
                    Export
                  </Button>
                )}
              </div>
            </Wrapper>
          </CardBody>
        </Card>
        <Card style={{ zIndex: 2 }}>
          <CardBody>
            <Wrapper>
              {loading ? (
                <Loader />
              ) : (
                <Table
                  columns={columns}
                  rowKey={(v) => v.id}
                  dataSource={data}
                  size="small"
                ></Table>
              )}
            </Wrapper>
          </CardBody>
        </Card>
      </Page>
    </DashboardLayout>
  );
};

export default RepairSummary;
