// NPM Packages
import React, { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { observer } from "mobx-react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Button, Table, DatePicker, Input, Select, Checkbox } from "antd";
import Axios from "axios";

// Components
import { useMst } from "../../store/RootStore";
import { Page } from "../../components";
import DashboardLayout from "../../layouts/Dashboard";
import Loader from "../../components/Loader";

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    render: (value: string) => value,
  },
  {
    title: "Market",
    dataIndex: "market",
    render: (value: string) => value,
  },
  {
    title: "Store",
    dataIndex: "store",
    render: (value: string) => value,
  },
  {
    title: "R&M Request No.",
    dataIndex: "request_number",
    render: (value: string) => value,
  },
  {
    title: "Date Creation of Request",
    dataIndex: "created_at",
    render: (value: string) => value,
  },
  {
    title: "Classification",
    dataIndex: "classification",
    render: (value: string) => value,
  },
  {
    title: "Asset No",
    dataIndex: "asset_number",
    render: (value: string) => value,
  },
  {
    title: "Total Cost of Repair",
    dataIndex: "total_cost_of_repair",
    render: (value: string) => value,
  },
];

const Wrapper = styled.div``;

const CostSummary: React.FC = observer(() => {
  const store = useMst();
  const [storesList, setStoreList] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [data2, setData2] = useState<any>(null);

  const [date, setDate] = useState(moment().format("YYYY-MM"));
  const [showPreviousYear, setShowPreviousYear] = useState(false);
  const [stores, setStores] = useState<any[]>([]);
  const [requestNumber, setRequestNumber] = useState("");
  const [classification, setClassification] = useState<null | number>(null);

  useEffect(() => {
    if (Array.isArray(data)) {
      handleSearch();
    }
  }, [showPreviousYear]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const token = store.userAccount.token;
      const response = await Axios.get("/api/references/stores/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setStoreList(response.data);

      if (response.data.length === 1) {
        setStores([response.data[0].id]);
      }
    })();
  }, []);

  const getHeaderLabel = (isPreviousYear: boolean = false) => {
    if (date) {
      let dateSplit = date.split("-");

      let year = dateSplit[0];
      if (isPreviousYear) {
        year = String(parseInt(dateSplit[0], 10) - 1);
      }

      return moment(`${year}-${dateSplit[1]}-1`).format("YYYY MMM");
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    const token = store.userAccount.token;
    const headers = { Authorization: `Bearer ${token}` };
    let response = await Axios.get(
      "/api/reports/repairs_maintenance_cost/summary",
      {
        params: {
          date,
          stores: stores.join(","),
          requestNumber,
          classification,
        },
        headers,
      }
    );
    setLoading(false);
    setData(response.data.data);

    if (showPreviousYear) {
      let dateSplit = date.split("-");
      const prevYear = parseInt(dateSplit[0], 10) - 1;

      response = await Axios.get(
        "/api/reports/repairs_maintenance_cost/summary",
        {
          params: {
            date: `${prevYear}-${dateSplit[1]}`,
            stores: stores.join(","),
            requestNumber,
            classification,
          },
          headers,
        }
      );
      setData2(response.data.data);
    }
  };

  const handleExport = async () => {
    const token = store.userAccount.token;
    const response = await Axios.get(
      "/api/reports/repairs_maintenance_cost/export",
      {
        responseType: "blob",
        params: {
          date,
          stores: stores.join(","),
          requestNumber,
          showPreviousYear,
          classification,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "cost-summary-report.xls");
    document.body.appendChild(link);
    link.click();
  };

  const handleSelectStore = (values: any) => {
    const lastSelected = values[values.length - 1];

    if (lastSelected === "all") {
      setStores(["all"]);
    } else {
      setStores(values.filter((el) => el !== "all"));
    }
  };

  const renderTable = (source) => {
    return (
      <Table
        columns={columns}
        rowKey={(v) => v.id}
        dataSource={source}
        size="small"
        summary={(pageData) => {
          let totalCost = 0;
          pageData.forEach(({ total_cost_of_repair }) => {
            totalCost += total_cost_of_repair;
          });

          if (pageData.length === 0) {
            return null;
          }

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={7}>
                <strong>Total</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <strong>{totalCost}</strong>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    );
  };

  return (
    <DashboardLayout>
      <Page>
        <Card>
          <CardHeader>Cost Summary Report</CardHeader>
          <CardBody>
            <Wrapper>
              <div>
                <label className="form-title mr-5">Month:</label>
                <DatePicker.MonthPicker
                  defaultValue={moment()}
                  disabledDate={(current) =>
                    current && current.valueOf() > Date.now()
                  }
                  onChange={(_, dateString) => setDate(dateString || "")}
                  placeholder="Select month"
                />{" "}
                <Checkbox
                  onChange={(e) => setShowPreviousYear(e.target.checked)}
                >
                  Include previous year?
                </Checkbox>
              </div>
              <div className="mt-3">
                <label className="form-title mr-5">Stores:</label>
                <Select
                  style={{ minWidth: 300 }}
                  placeholder="Select store/s"
                  mode="multiple"
                  onChange={handleSelectStore}
                  value={stores}
                >
                  <Select.Option value="all">All Stores</Select.Option>
                  {storesList.map((store: any) => (
                    <Select.Option
                      key={store.id}
                      value={store.id}
                      disabled={
                        stores.length >= 3
                          ? stores.includes(store.id)
                            ? false
                            : true
                          : false
                      }
                    >
                      {store.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="mt-3">
                <label className="form-title mr-5">R&amp;M Request No:</label>
                <Input
                  style={{ width: 300 }}
                  onChange={(e) => setRequestNumber(e.target.value)}
                />
              </div>
              <div className="mt-3">
                <label className="form-title mr-5">Classification:</label>
                <Select
                  onChange={(value: string) =>
                    setClassification(parseInt(value))
                  }
                  allowClear
                  className="mb-2"
                  style={{ minWidth: 180 }}
                  placeholder="Filter by classification"
                >
                  {store.references.assetClassifications.map(
                    (classification) => (
                      <Select.Option
                        key={classification.id}
                        value={classification.id}
                      >
                        {classification.description}
                      </Select.Option>
                    )
                  )}
                </Select>
              </div>
              <div className="mt-3">
                <Button className="mr-2" type="primary" onClick={handleSearch}>
                  Search
                </Button>
                {(data?.length > 0 || data2?.length > 0) && (
                  <Button type="primary" onClick={handleExport}>
                    Export
                  </Button>
                )}
              </div>
            </Wrapper>
          </CardBody>
        </Card>
        <Card style={{ zIndex: 2 }}>
          <CardBody>
            <Wrapper>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className="text-center">{getHeaderLabel()}</div>
                  {renderTable(data)}

                  {showPreviousYear ? (
                    <div className="mt-5">
                      <div className="text-center">{getHeaderLabel(true)}</div>
                      {renderTable(data2)}
                    </div>
                  ) : null}
                </>
              )}
            </Wrapper>
          </CardBody>
        </Card>
      </Page>
    </DashboardLayout>
  );
});

export default CostSummary;
