import React, { useState } from 'react';
import styled from 'styled-components';
import { getSnapshot } from 'mobx-state-tree';
import { Button, Input } from 'antd';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import humps from 'humps';
import localforage from 'localforage';

import config from '../../config/config';
import { FormRow, FormSection } from '../../components';
import { useMst } from '../../store/RootStore';
import { UserAccountType } from '../../types';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import Bugsnag from '@bugsnag/js';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  background-color: #a3080e;
  min-height: 100vh;
  .navbar-horizontal.navbar-transparent {
    position: absolute;
    z-index: 100;
    top: 0;
    width: 100%;
    border: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .bg-login-secondary {
    background-color: #fff1e2;
  }
  .header {
    position: relative;
  }
  .login-fill-default {
    fill: #a3080c !important;
  }
  .ant-input-affix-wrapper {
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  }
  .login-container {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
`;

const LoginContainer = styled.div`
  padding: 40px;
  background: white;
  /* border-top: 5px solid #5a5c69;
  border-radius: 5px; */
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 4px 0px;

  .title {
    color: #a3080b;
  }

  .flex-grow {
    flex: 1;
  }

  @media (max-width: 1024px) {
    padding: 40px;

    .right {
      display: none !important;
    }

    .left {
      margin: 0px auto;
      max-width: 400px;
      width: auto;
    }
  }

  @media (max-width: 411px) {
    padding: 30px;
  }

  @media (max-width: 390px) {
    padding: 30px;
  }
`;

interface FormData {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Must be a valid email'),
  password: yup.string().required('Password is required'),
});

const Login: React.FC = () => {
  const store = useMst();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  const { errors, handleSubmit, control } = useForm<FormData>({
    validationSchema: schema,
  });

  const onSubmit = async (data: FormData) => {
    try {
      setLoginError(null);
      setLoading(true);

      const response = await store.login(humps.decamelizeKeys(data));
      const userData = response.data.data as UserAccountType;
      store.userAccount.save(userData);
      await store.userAccount.getNotifications();
      const userDataSerialized = getSnapshot(store.userAccount);
      await localforage.setItem(config.authTokenKey, userDataSerialized);
      setLoading(false);
      history.push('/dashboard');
    } catch (error) {
      if (error instanceof Error) {
        setLoading(false);
        setLoginError(error.message);
      }
    }
  };

  return (
    <>
      <Wrapper>
        <nav id="navbar-main" className="navbar navbar-horizontal navbar-transparent navbar-main navbar-expand-lg navbar-light">
          <div className="container">
            <a className="text-dark font-weight-bold" href="dashboard.html">
              Repairs &amp; Maintenance
            </a>
          </div>
        </nav>
        <div className="main-content">
          <div className="header bg-login-secondary py-7 py-lg-8 pt-lg-9">
            <div className="container">
              <div className="header-body text-center mb-7">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-6 col-md-8 px-5">
                    <h1 className="text-dark">LOGIN</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <polygon className="login-fill-default" points="2560 0 2560 100 0 100"></polygon>
              </svg>
            </div>
          </div>
          <div className="container mt--8 pb-5">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-7">
                <div className="card bg-secondary border-0 mb-0">
                  <div className="card-body px-lg-5 py-lg-5 p-5 login-container">
                    {config.stage === 'staging' ? (
                      <img
                        className="image"
                        src="https://repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/asset.png"
                      />
                    ) : (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <FormSection>
                          {loginError ? <span className="error-message">{loginError}</span> : null}
                          <FormRow>
                            <div className="controls">
                              <Controller
                                name="email"
                                control={control}
                                as={<Input placeholder="Email" size="large" prefix={<MailOutlined />} />}
                              />
                            </div>
                            {errors['email'] ? <span className="error-message">{errors['email'].message}</span> : null}
                          </FormRow>
                          <FormRow>
                            <div className="controls">
                              <Controller
                                name="password"
                                control={control}
                                as={<Input.Password placeholder="Password" prefix={<LockOutlined />} size="large" />}
                              />
                            </div>
                            {errors['password'] ? <span className="error-message">{errors['password'].message}</span> : null}
                          </FormRow>
                          <FormRow className="text-center mb-0">
                            <Button loading={loading} htmlType="submit" type="danger" className="login-btn">
                              Login
                            </Button>
                          </FormRow>
                        </FormSection>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="py-5 footer-auto-bottom" id="footer-main">
            <div className="container">
              <div className="row align-items-center justify-content-xl-between">
                <div className="col-xl-12">
                  <div className="copyright text-center text-xl-center text-muted">
                    © 2021 <a href="#" className="font-weight-bold text-light ml-1" target="_blank">Hiflyer</a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </Wrapper>
      <link rel="stylesheet" href="/argon-react.min.css" />
    </>
  );
};

export default Login;
