import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { Select, Input, InputNumber, Modal, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';

import { FormSection, FormRow } from '../../../components';
import { TimekeepingFormData, TimekeepingModalProps } from '../../../types';

const schema = yup.object().shape({
  timeOutOffice: yup.string().required('Time out office is required'),
  timeInStore: yup.string().required('Time in store is required'),
  timeOutStore: yup.string().required('Time out store is required'),
  timeInOffice: yup.string().required('Time in office is required'),
  breaktimeNumberOfMinutes: yup
    .number()
    .required('Break (minutes) is required')
    .test('breaktimeNumberOfMinutes', 'Must be greater than or equal to zero', value => value >= 0),
  assetId: yup.number().required('Asset is required'),
  workDone: yup.string().required('Work done is required'),
});

const TimekeepingModal: React.FC<TimekeepingModalProps> = ({
  visible,
  timekeeping,
  technicians,
  items,
  onSubmit,
  onCancel,
}) => {
  const { control, handleSubmit, errors, reset } = useForm<TimekeepingFormData>({
    validationSchema: schema,
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  const onInnerSubmit = (values: TimekeepingFormData) => {
    if (timekeeping) {
      values.id = timekeeping.id;
    }
    reset();
    onSubmit && onSubmit(values);
    setSubmitLoading(false);
  };

  useEffect(() => {
    control.setValue('assetId', timekeeping && timekeeping.asset ? timekeeping.asset.id : 0);
    control.setValue('technicianId', timekeeping && timekeeping.technician ? timekeeping.technician.id : 0);
    control.setValue('timeOutOffice', timekeeping ? moment(timekeeping.timeOutOffice) : moment());
    control.setValue('timeInStore', timekeeping ? moment(timekeeping.timeInStore) : moment());
    control.setValue('timeOutStore', timekeeping ? moment(timekeeping.timeOutStore) : moment());
    control.setValue('timeInOffice', timekeeping ? moment(timekeeping.timeInOffice) : moment());
    control.setValue('workDone', timekeeping ? timekeeping.workDone : '');
    control.setValue('breaktimeNumberOfMinutes', timekeeping ? timekeeping.breaktimeNumberOfMinutes : 0);
  }, [timekeeping]);

  const handleSubmitHof = () => {
    const h = handleSubmit(onInnerSubmit);
    return async () => {
      setSubmitLoading(true);
      setTimeout(async () => {
        await h();
        setSubmitLoading(false);
      }, 200);
    };
  };

  return (
    <Modal
      className="white-content"
      title="Timekeeping"
      okButtonProps={{ loading: submitLoading }}
      cancelButtonProps={{ loading: submitLoading }}
      onOk={handleSubmitHof()}
      onCancel={() => onCancel && onCancel()}
      visible={visible}
    >
      <form autoComplete="off">
        <FormSection className="">
          <div className="d-flex flex-row">
            <FormRow className={errors['assetId'] ? 'has-error mr-3' : 'mr-3'}>
              <label className="form-title">Technician:</label>
              <Controller
                name="technicianId"
                defaultValue={timekeeping?.technician?.id}
                control={control}
                as={
                  <Select allowClear style={{ width: 230 }}>
                    {technicians.map(technician => (
                      <Select.Option key={technician.id} value={technician.id}>
                        {technician.firstName} {technician.lastName}
                      </Select.Option>
                    ))}
                  </Select>
                }
              />
              {errors['technicianId'] ? (
                <span className="error-message mt-2">{errors['technicianId'].message}</span>
              ) : null}
            </FormRow>
            <FormRow className={errors['assetId'] ? 'has-error' : ''}>
              <label className="form-title">Asset:</label>
              <Controller
                name="assetId"
                defaultValue={timekeeping?.asset?.id}
                control={control}
                as={
                  <Select allowClear style={{ width: 230 }}>
                    {items.map(item => (
                      <Select.Option key={item.asset.id} value={item.asset.id}>
                        {item.asset.assetTaggingNo} - {item.asset.assetDescription}
                      </Select.Option>
                    ))}
                  </Select>
                }
              />
              {errors['assetId'] ? <span className="error-message mt-2">{errors['assetId'].message}</span> : null}
            </FormRow>
          </div>
          <div className="d-flex flex-row">
            <FormRow className={errors['timeOutOffice'] ? 'has-error mr-3' : 'mr-3'}>
              <label className="form-title">Time-out Office:</label>
              <Controller
                name="timeOutOffice"
                defaultValue={timekeeping ? moment(timekeeping.timeOutOffice) : null}
                control={control}
                as={<DatePicker format="YYYY-MM-DD h:mm a" showTime />}
              />
              {errors['timeOutOffice'] ? <span className="error-message mt-2">Time-out office is required</span> : null}
            </FormRow>
            <FormRow className={errors['timeInStore'] ? 'has-error ' : ''}>
              <label className="form-title">Time-in Store:</label>
              <Controller
                name="timeInStore"
                defaultValue={timekeeping ? moment(timekeeping.timeInStore) : null}
                control={control}
                as={<DatePicker format="YYYY-MM-DD h:mm a" showTime />}
              />
              {errors['timeInStore'] ? <span className="error-message mt-2">Time-in store is required</span> : null}
            </FormRow>
          </div>
          <div className="d-flex flex-row">
            <FormRow className={errors['timeOutStore'] ? 'has-error mr-3' : 'mr-3'}>
              <label className="form-title">Time-out store:</label>
              <Controller
                name="timeOutStore"
                defaultValue={timekeeping ? moment(timekeeping.timeOutStore) : null}
                control={control}
                as={<DatePicker format="YYYY-MM-DD h:mm a" showTime />}
              />
              {errors['timeOutStore'] ? <span className="error-message mt-2">Time-out store is required</span> : null}
            </FormRow>
            <FormRow className={errors['timeInOffice'] ? 'has-error' : ''}>
              <label className="form-title">Time-in Office:</label>
              <Controller
                name="timeInOffice"
                defaultValue={timekeeping ? moment(timekeeping.timeInOffice) : null}
                control={control}
                as={<DatePicker format="YYYY-MM-DD h:mm a" showTime />}
              />
              {errors['timeInOffice'] ? <span className="error-message mt-2">Time-in office is required</span> : null}
            </FormRow>
          </div>
          <div className="d-flex flex-row">
            <FormRow className={errors['breaktimeNumberOfMinutes'] ? 'has-error' : ''}>
              <label className="form-title">Break (minutes):</label>
              <Controller
                name="breaktimeNumberOfMinutes"
                defaultValue={timekeeping?.breaktimeNumberOfMinutes}
                control={control}
                as={<InputNumber />}
              />
              {errors['breaktimeNumberOfMinutes'] ? (
                <span className="error-message mt-2">{errors['breaktimeNumberOfMinutes'].message}</span>
              ) : null}
            </FormRow>
          </div>
          <FormRow className={errors['workDone'] ? 'has-error' : ''}>
            <label className="form-title">Work done:</label>
            <Controller
              name="workDone"
              defaultValue={timekeeping?.workDone}
              control={control}
              as={<Input.TextArea rows={4} />}
            />
            {errors['workDone'] ? <span className="error-message mt-2">{errors['workDone'].message}</span> : null}
          </FormRow>
        </FormSection>
      </form>
    </Modal>
  );
};

TimekeepingModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  timekeeping: PropTypes.any,
  items: PropTypes.any.isRequired,
  technicians: PropTypes.any.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default TimekeepingModal;
