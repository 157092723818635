import React, { useState, useEffect } from 'react';
import { Skeleton, notification } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';

import DashboardLayout from '../../layouts/Dashboard';
import { Page } from '../../components';
import { PropertyTransferRequestItem, PropertyTransferRequest } from '../../types';
import { useMst } from '../../store/RootStore';
import config from '../../config/config';
import DataTable from 'react-data-table-component';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Input,
} from "reactstrap";

const action_link = (ref: any) => {
  return (
    <Link className='btn btn-outline-danger btn-sm' to={`/property_transfers/${ref}`}>View</Link>
  );
};

const columns = [
  {
    name: 'PTAF Number',
    selector: (row: { ptafNumber: any }) => row.ptafNumber,
    sortable: true,
  },
  {
    name: 'Recipient',
    selector: (row: { recipient: any }) => `${row.recipient.firstName} ${row.recipient.lastName}`,
    sortable: true,
  },
  {
    name: 'Transfer type',
    selector: (row: { transfertype: any }) => config.ptafTypes[row.transfertype],
    sortable: true,
  },
  {
    name: 'Date issued',
    selector: (row: { dateIssued: any }) => moment(row.dateIssued).format('YYYY-MM-DD'),
    sortable: true,
  },
  {
    name: 'Date applied',
    selector: (row: { dateApplied: any }) => moment(row.dateApplied).format('YYYY-MM-DD'),
    sortable: true,
  },
  {
    name: 'No. of items',
    selector: (row: { items: PropertyTransferRequestItem[] }) => row.items.length,
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row: { status: any }) => config.propertyTransferRequestStatusReference[row.status].label,
    sortable: true,
  },
  {
    name: 'Actions',
    selector: (row: { uuid: any }) => row.uuid,
    cell: (row: { uuid: any }) => action_link(row.uuid),
  },
];

const Wrapper = styled.div``;

const MyProperyTransferRequests: React.FC = () => {
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PropertyTransferRequest[]>([]);




  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = data.filter(
    item => item.ptafNumber && item.ptafNumber.toLowerCase().includes(filterText.toLowerCase()) || item.recipient.firstName && item.recipient.firstName.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <Input placeholder='Search..' onChange={(e: { target: { value: React.SetStateAction<string> } }) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);


  useEffect(() => {
    setLoading(true);
    store.userAccount
      .getMyPropertyTransferRequests()
      .then(response => {
        setData(response.data.data);
      })
      .catch(err => {
        notification['error']({
          message: 'My Property Transfer Requests',
          description: 'An error occurred while fetching your data. Please try again later.',
        });
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <Page>
        <Card style={{ zIndex: 2 }}>
          <CardHeader>
            My Property Transfer Requests
          </CardHeader>
          <CardBody className="data-table">
            <Wrapper>
              {loading ? (
                <Skeleton />
              ) : (
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  responsive
                  fixedHeader
                  fixedHeaderScrollHeight="300px"
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                />
              )}
            </Wrapper>
          </CardBody>
        </Card>
      </Page>
    </DashboardLayout>
  );
};

export default MyProperyTransferRequests;
