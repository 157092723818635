import React, { useState, useEffect } from 'react';
import { Skeleton } from 'antd';
import { Page } from '../../components';
import { useMst } from '../../store/RootStore';
import { AssetType } from '../../types';
import DataTable from 'react-data-table-component';

// reactstrap components
import {
  CardHeader,
  CardBody,
  Card,
  Input,
  Row,
  Col,
} from "reactstrap";
import DashboardLayout from '../../layouts/Dashboard';

const Assets: React.FC = () => {
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState<AssetType[]>([]);

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = assets.filter(
    item => item.assetDescription && item.assetDescription.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <Input placeholder='Search..' onChange={(e: { target: { value: React.SetStateAction<string> } }) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: 'Asset No.',
      selector: (assets: { assetTaggingNo: any }) => assets.assetTaggingNo,
      sortable: true,
    },
    {
      name: 'Classification',
      selector: (assets: { classification: any }) => assets.classification?.description,
      sortable: true,
    },
    {
      name: 'Description',
      selector: (assets: { assetDescription: any }) => assets.assetDescription,
      sortable: true,
    },
    {
      name: 'Brand',
      selector: (assets: { brand: any }) => assets.brand,
      sortable: true,
    },
    {
      name: 'Cost',
      sortable: true,
    },
  ];


  useEffect(() => {
    setLoading(true);
    store.userAccount.getAssets().then(response => {
      setAssets(response.data.data);
      setLoading(false);
    });
  }, []);

  return (
    <DashboardLayout>
      <Page>
        <Row>
          <Col xs="12">
            {loading ? (
              <Card className="card-chart">
                <Skeleton />
                <Skeleton />
              </Card>
            ) : (
              <Card>
                <CardHeader>
                  Assets
                </CardHeader>
                <CardBody className="data-table">
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    responsive
                    fixedHeader
                    fixedHeaderScrollHeight="300px"
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Page>
    </DashboardLayout>
  );
};

export default Assets;
