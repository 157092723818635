import React, { useState, useEffect } from 'react';
import { Table, Skeleton, notification } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { TiHome, TiInputChecked, TiArrowForward, TiSpanner } from 'react-icons/ti';

import DashboardLayout from '../../layouts/Dashboard';
import { Page, Card, HorizontalLine } from '../../components';
import {
  UserType,
  MaintenanceRequestHeaderType,
  MaintenanceRequestTypeModelType,
  MaintenanceRequestItemType,
} from '../../types';
import { useMst } from '../../store/RootStore';
import config from '../../config/config';
import DataTable from 'react-data-table-component';

const columns = [
  {
    name: 'Reference number',
    sortable: true,
  },
  {
    name: 'No. of items',
    sortable: true,
  },
  {
    name: 'Date applied',
    sortable: true,
  },
  {
    name: 'Status',
    sortable: true,
  },
  {
    name: 'Actions',
  },
];

const Wrapper = styled.div``;

const WorkInProgress: React.FC = () => {
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MaintenanceRequestTypeModelType[]>([]);

  useEffect(() => {
    setLoading(true);
    store.userAccount
      .getMRTypeWorkInProgress()
      .then(response => {
        setData(response.data.data);
      })
      .catch(err => {
        notification['error']({
          message: 'Work Management',
          description: 'An error occurred while fetching your data. Please try again later.',
        });
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <Page>
        <Card style={{ zIndex: 2 }}>
          <div className="card-body">
            <Wrapper>
              <p className="card-title">Work management</p>
              {loading ? <Skeleton /> :
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                />}
            </Wrapper>
          </div>
        </Card>
      </Page>
    </DashboardLayout>
  );
};

export default WorkInProgress;
