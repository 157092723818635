import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Select } from 'antd';
import { observer } from 'mobx-react';
import * as JsSearch from 'js-search';
import { Input, Tooltip, Button } from 'antd';
import PropTypes from 'prop-types';
import { FaTrademark, FaPlus, FaMoneyBillWaveAlt, FaToolbox, FaTag, FaBuilding } from 'react-icons/fa';
import { ListChildComponentProps } from 'react-window';
import { FixedSizeList as List } from 'react-window';

import { Card } from '../index';
import { AssetType } from '../../types';
import { Search } from '../../utils/search';
import { RowWrapper, ListWrapper, Wrapper } from './styles';
import { EmptyMessage } from '../EmptyMessage';
import { useMst } from '../../store/RootStore';

interface ComponentProps {
  assets: AssetType[];
  visible: boolean;
  onSubmit?: (assets: { [key: string]: AssetType }) => void;
  onChange?: (assets: { [key: string]: AssetType }) => void;
  onCancel: () => void;
}

export const AssetsSelect: React.FC<ComponentProps> = observer(({ assets, visible, onChange, onCancel }) => {
  const store = useMst();
  const [selectedAssets, selectSelectedAssets] = useState<{ [key: string]: AssetType }>({});

  const assetSearch = useMemo<JsSearch.Search>(() => {
    const assetSearch = Search('assetTaggingNo', ['assetTaggingNo', 'classification.description', 'assetDescription']);
    assetSearch.addDocuments(assets);
    return assetSearch;
  }, []);

  const [filterClassification, setFilterClassification] = useState<null | number>(null);
  const [filterText, setFilterText] = useState('');
  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    setFilterText(value);
  };

  let visibleAssets = assets;
  if (filterText.trim().length) {
    visibleAssets = assetSearch.search(filterText) as AssetType[];
  }
  if (filterClassification) {
    visibleAssets = visibleAssets.filter(asset => asset.classification?.id === filterClassification);
  }

  const Row: React.ComponentType<ListChildComponentProps> = ({ index, style }) => {
    const asset = visibleAssets[index];
    return (
      <RowWrapper style={style}>
        <div className="d-flex">
          {/* <div className="image-wrapper"></div> */}
          <div style={{ flex: 1 }}>
            <span className="asset-row title-row">
              {asset.isGeneric ? (
                <Tooltip title="Description">
                  <span className="title">{asset.assetDescription}</span>
                </Tooltip>
              ) : (
                <Tooltip title="Asset No">
                  <span className="title">{asset.assetTaggingNo}</span>
                </Tooltip>
              )}

              {selectedAssets[asset.assetId] ? (
                <Button
                  type="primary"
                  className="custom-btn-primary"
                  danger
                  size="small"
                  onClick={() => {
                    if (Object.keys(selectedAssets).includes(asset.assetId)) {
                      const tmp = { ...selectedAssets };
                      delete tmp[asset.assetId];
                      selectSelectedAssets(tmp);
                    }
                  }}
                >
                  Remove
                </Button>
              ) : (
                <Button
                  type="primary"
                  className="custom-btn-primary"
                  size="small"
                  onClick={() => {
                    if (!selectedAssets[asset.assetId]) {
                      const tmp = { ...selectedAssets, [asset.assetId]: asset };
                      selectSelectedAssets(tmp);
                    }
                  }}
                >
                  <FaPlus style={{ fontSize: 12, marginRight: 10 }} />
                  Add
                </Button>
              )}
            </span>
            <p className="asset-row">
              <Tooltip title="Classification">
                <span>
                  <FaToolbox className="icon" />
                  <span className="value">{asset.classification?.description}</span>
                </span>
              </Tooltip>
            </p>
            {!asset.isGeneric ? (
              <p className="asset-row">
                <Tooltip title="Description/Brand Name">
                  <span>
                    <FaTrademark className="icon" />
                    <span className="value">{asset.assetDescription}</span>
                  </span>
                </Tooltip>
                <Tooltip title="Brand">
                  <span className="value">{asset.brand}</span>
                </Tooltip>
              </p>
            ) : null}
            <p className="asset-row">
              <Tooltip title="cost">
                <span>
                  <FaMoneyBillWaveAlt className="icon" />
                  <span className="value">N/A</span>
                </span>
              </Tooltip>
            </p>
          </div>
        </div>
      </RowWrapper>
    );
  };
  Row.propTypes = {
    index: PropTypes.any,
    style: PropTypes.any,
  };

  return (
    <Modal
      className="white-content"
      title="Select assets"
      visible={visible}
      style={{ top: 20 }}
      width={800}
      onCancel={onCancel}
      onOk={() => {
        onChange && onChange(selectedAssets);
        selectSelectedAssets({});
      }}
    >
      <div className='white-content'>
        <Wrapper>
          <Card flat={true} noPadding={true}>
            <div className="card-body">
              <div className="d-flex flex-row">
                <div className="mb-2 d-flex flex-column">
                  <Select
                    onChange={(value: string) => setFilterClassification(parseInt(value))}
                    allowClear
                    className="mb-2"
                    style={{ minWidth: 180 }}
                    placeholder="Filter by classification"
                  >
                    {store.references.assetClassifications.map(classification => (
                      <Select.Option key={classification.id} value={classification.id}>
                        {classification.description}
                      </Select.Option>
                    ))}
                  </Select>
                  <Input.Search placeholder="Search..." onKeyUp={handleKeyUp} />
                </div>
                <label htmlFor="" className="items-title d-flex flex-column align-items-end">
                  <span className="label">
                    You have <b>{assets.length}</b> assets
                  </span>
                  {Object.keys(selectedAssets).length ? (
                    <span className="items-selected">
                      <b className="item-count">{Object.keys(selectedAssets).length}</b> item/s selected
                    </span>
                  ) : null}
                </label>
              </div>
              <ListWrapper>
                {visibleAssets.length === 0 ? (
                  <EmptyMessage>
                    <span className="text">No item to display</span>
                  </EmptyMessage>
                ) : (
                  <List
                    height={visibleAssets.length > 1 ? 330 : 110}
                    itemCount={visibleAssets.length}
                    itemSize={100}
                    width="100%"
                  >
                    {Row}
                  </List>
                )}
              </ListWrapper>
            </div>
          </Card>
        </Wrapper>
      </div>
    </Modal>
  );
});

AssetsSelect.propTypes = {
  assets: PropTypes.any.isRequired,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
};
