import React, { useState, useEffect } from 'react';
import { Skeleton, notification } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Page } from '../../components';
import {
  MaintenanceRequestTypeModelType,
  MaintenanceRequestItemType,
} from '../../types';
import { useMst } from '../../store/RootStore';
import config from '../../config/config';
import Links from './Links';
import { observer } from 'mobx-react';
import DataTable from 'react-data-table-component';

import {
  Card,
  Input,
  CardHeader,
  CardBody,
} from "reactstrap";
import DashboardLayout from '../../layouts/Dashboard';

const action_link = (ref: any) => {
  return (
    <Link className='btn btn-outline-danger btn-sm' to={`/work_management/${ref}`}>View</Link>
  );
};

const columns = [
  {
    name: 'Reference number',
    selector: (row: { referenceNumber: any }) => row.referenceNumber,
    sortable: true,
  },
  {
    name: 'No. of items',
    selector: (row: { items: MaintenanceRequestItemType[] }) => row.items ? row.items.length : 0,
    sortable: true,
  },
  {
    name: 'Date applied',
    selector: (row: { header: any }) => moment(row.header.createdAt).format('YYYY-MM-DD'),
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row: { status: any }) => config.maintenanceRequestTypeStatusLabels[row.status].label,
    sortable: true,
  },
  {
    name: 'Actions',
    selector: (row: { referenceNumber: any }) => row.referenceNumber,
    cell: (row: { referenceNumber: any }) => action_link(row.referenceNumber),
  },
];

const Wrapper = styled.div``;

const Work: React.FC = observer(() => {
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MaintenanceRequestTypeModelType[]>([]);

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = data.filter(
    item => item.referenceNumber && item.referenceNumber.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <Input placeholder='Search..' onChange={(e: { target: { value: React.SetStateAction<string> } }) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    setLoading(true);
    store.userAccount
      .getWorksForStoreApproval()
      .then(response => {
        setData(response.data.data);
      })
      .catch(err => {
        notification['error']({
          message: 'Work Management',
          description: 'An error occurred while fetching your data. Please try again later.',
        });
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <Page>
        <Links active="work_management" access={store.userAccount.access} />
        {loading ? (
          <Card>
            <div className="card-body">
              <Skeleton />
            </div>
          </Card>
        ) : (
          <Wrapper>
            <Card style={{ zIndex: 2, width: '100%' }}>
              <CardHeader>
                Approvals - Work Management
              </CardHeader>
              <CardBody className="data-table">
                <Wrapper>
                  {loading ? <Skeleton /> :
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      fixedHeader
                      fixedHeaderScrollHeight="300px"
                      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                    />}
                </Wrapper>
              </CardBody>
            </Card>
          </Wrapper>
        )}
      </Page>
    </DashboardLayout>
  );
});

export default Work;
