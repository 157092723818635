import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Axios from "axios";
import styled from "styled-components";
import moment from "moment";
import { Table, Skeleton, Button } from "antd";
import { useMst } from "../../store/RootStore";
import { Card, CardBody } from "reactstrap";

import DashboardLayout from "../../layouts/Dashboard";
import { Page } from "../../components";

const columns = [
  {
    title: "Date Requested",
    dataIndex: "date_requested",
    render: (value: string) =>
      value ? moment(value).format("YYYY-MM-DD") : "",
  },
  {
    title: "Date Approved",
    dataIndex: "date_approved",
    render: (value: string) =>
      value ? moment(value).format("YYYY-MM-DD") : "",
  },
  {
    title: "Store Number",
    dataIndex: "store",
    render: (value: string) => value,
  },
  {
    title: "MR Reference Number",
    dataIndex: "mr_ref_no",
    render: (value: string) => value,
  },
  {
    title: "Description",
    dataIndex: "description",
    render: (value: string) => value,
  },
  {
    title: "Service Technician",
    dataIndex: "technician",
    render: (value: string) => value,
  },
  {
    title: "Repair Cost",
    dataIndex: "total_repair_cost",
    render: (value: string) => parseFloat(value).toFixed(2),
  },
];

const Wrapper = styled.div``;

const AssetHistoryDetails: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const store = useMst();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.replace(history.location.pathname, location.state);
      }
    };
  }, [history]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const token = store.userAccount.token;
      const headers = { Authorization: `Bearer ${token}` };
      let response = await Axios.get(
        `/api/reports/assets_history/details/${id}`,
        {
          headers,
        }
      );

      setLoading(false);
      setData(response.data.data);
    })();
  }, []);

  const handleExport = async () => {
    const token = store.userAccount.token;
    console.log({ asset_tagging_no: id });
    const response = await Axios.get(
      "/api/reports/assets_history/details/export",
      {
        responseType: "blob",
        params: { asset_tagging_no: id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "asset-history-details.xls");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <DashboardLayout>
      <Page>
        <Link
          className="text-white font-weight-bold"
          to={{
            pathname: "/reports/asset_history",
            state: location.state,
          }}
        >
          &laquo; Back to Assets History
        </Link>
        <Card style={{ zIndex: 2 }} className="mt-2">
          <CardBody>
            {data?.length > 0 && (
              <Button className="mb-2" type="primary" onClick={handleExport}>
                Export
              </Button>
            )}
            <Wrapper>
              {loading ? (
                <Skeleton />
              ) : (
                <>
                  <Table
                    columns={columns}
                    rowKey={(v) => v.id}
                    dataSource={data}
                    size="small"
                  ></Table>
                </>
              )}
            </Wrapper>
          </CardBody>
        </Card>
      </Page>
    </DashboardLayout>
  );
};

export default AssetHistoryDetails;
