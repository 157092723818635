import React, { useState, useEffect } from 'react';
import { Skeleton, notification } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';

import DashboardLayout from '../../layouts/Dashboard';
import { Page } from '../../components';
import {
  MaintenanceRequestTypeModelType,
  MaintenanceRequestsResponse,
} from '../../types';
import { useMst } from '../../store/RootStore';
import config from '../../config/config';
import DataTable from 'react-data-table-component';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Input,
} from "reactstrap";


const action_link = (ref: any) => {
  return (
    <Link className='btn btn-outline-danger btn-sm' to={`/maintenance_requests/${ref}`}>View</Link>
  );
};

const columns = [
  {
    name: 'Reference number',
    selector: (row: { referenceNumber: any }) => row.referenceNumber,
    sortable: true,
  },
  {
    name: 'Requestor Name',
    selector: (row: { requestorName: any }) => row.requestorName,
    sortable: true,
  },
  {
    name: 'Total Requests',
    selector: (row: { types: MaintenanceRequestTypeModelType[] }) => (row.types ? row.types.length : 0),
    sortable: true,
  },
  {
    name: 'No. of items',
    selector: (row: { types: MaintenanceRequestTypeModelType[] }) => row.types ? row.types.map(type => type.items.length).reduce((p, c) => p + c, 0) : 0,
    sortable: true,
  },
  {
    name: 'Date applied',
    selector: (row: { createdAt: any }) => moment(row.createdAt).format('YYYY-MM-DD'),
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row: { status: any }) => config.maintenanceRequestStatusLabels[row.status],
    sortable: true,
  },
  {
    name: 'Actions',
    cell: (row: { uuid: any }) => action_link(row.uuid),
  },
];

const Wrapper = styled.div``;

const MyProperyTransferRequests: React.FC = () => {
  const store = useMst();


  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<MaintenanceRequestsResponse>({
    data: [],
    meta: {
      current_page: 1,
      from: 1,
      last_page: 1,
      path: '',
      per_page: 10,
      to: 1,
      total: 1,
    },
  });



  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = data.data.filter(
    item => item.referenceNumber && item.referenceNumber.toLowerCase().includes(filterText.toLowerCase()) || item.requestorName && item.requestorName.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <Input placeholder='Search..' onChange={(e: { target: { value: React.SetStateAction<string> } }) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);


  const onPageChange = (page: number): void => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setLoading(true);
    store.userAccount
      .getMyMaintenanceRequests(currentPage)
      .then(response => {
        setData(response.data);
      })
      .catch(err => {
        notification['error']({
          message: 'My Maintenance Requests',
          description: 'An error occurred while fetching your data. Please try again later.',
        });
      })
      .then(() => {
        setLoading(false);
      });
  }, [currentPage]);

  return (
    <DashboardLayout>
      <Page>
        <Card style={{ zIndex: 2 }}>
          <CardHeader>
            My Maintenance Requests
          </CardHeader>
          <CardBody className="data-table">
            <Wrapper>
              {loading ? (
                <Skeleton />
              ) : (
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  responsive
                  fixedHeader
                  fixedHeaderScrollHeight="300px"
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                />
              )}
            </Wrapper>
          </CardBody>
        </Card>
      </Page>
    </DashboardLayout>
  );
};

export default MyProperyTransferRequests;
