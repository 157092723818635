// @ts-nocheck
import React, { useState, useEffect, useCallback } from "react";
import * as yup from "yup";
import { observer } from "mobx-react";

import { PlusCircleFilled } from "@ant-design/icons";
import { FaTrademark, FaMoneyBillWaveAlt, FaToolbox } from "react-icons/fa";
import {
  DatePicker,
  Skeleton,
  Table,
  Modal,
  Select,
  notification,
  Tooltip,
} from "antd";
import { Controller, useForm } from "react-hook-form";

import config from "../../config/config";
import { useMst } from "../../store/RootStore";

import { TableWrapper, ModalWrapper, Wrapper } from "./styles";
import {
  UserType,
  AssetType,
  CreatePropertyTransferRequestFormData,
  TrackingId,
} from "../../types";
import {
  Section,
  AssetsSelect,
  FormSection,
  FormRow,
  Page,
  EmptyMessage,
} from "../../components";
import { RowWrapper as AssetItemRowWrapper } from "../../components/AssetsSelect/styles";
import { useHistory } from "react-router-dom";
import { PropertyTransferItemTableColumns } from "./common";

import DashboardLayout from "../../layouts/Dashboard";
import UserFilter from '../../components/UserFilter';
import {
  Button,
  Card,
  CardHeader,
  Input,
  Row,
  Col,
  Container,
  CardBody,
  CardTitle,
} from "reactstrap";

const schema = yup.object().shape({
  type: yup.string().required("Type is required"),
  recipient: yup.number().required("Recipient is required"),
  remarksField: yup.string().required("Remarks Field is required"),
  // ptafNumber: yup.string().required('PTAF Number is required'),
  dateIssued: yup.string().required("Date issued is required"),
  items: yup
    .object()
    .test("items", "Please select item/s to transfer", (value) => {
      return value && Object.keys(value).length > 0;
    }),
});

const ptafNumberType = {
  ptafNumber: String,
};

const AdminCreatePropertyTransferRequest: React.FC = observer(() => {
  const store = useMst();
  const history = useHistory();
  const references = store.references;

  const { control, handleSubmit, errors } =
    useForm<CreatePropertyTransferRequestFormData>({
      validationSchema: schema,
    });

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [assetsModalVisible, setAssetsModalVisible] = useState(false);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [assets, setAssets] = useState<AssetType[]>(
    store.userAccount.user ? store.userAccount.user.assets : []
  );
  const [data, setData] =
    useState<CreatePropertyTransferRequestFormData | null>(null);
  const [ptafDefaultValue, setPtafDefaultValue] = useState<TrackingId | string>(
    ""
  );

  const [searchRecipientText, setSearchRecipientText] = useState<string | null>(null);

  useEffect(() => {
    store.userAccount
      .getAssets()
      .then((response) => {
        const assets = response.data.data;
        if (store.userAccount.user) {
          store.userAccount.user.setAssets(assets);
          setPtafDefaultValue(response.data.trackingId);
        }
      })
      .catch((e) => console.log(e))
      .then(() => {
        setDataLoading(false);
      });
  }, []);

  const onDisplayReview = (values: CreatePropertyTransferRequestFormData) => {
    setReviewModalVisible(true);
    setData(values);
  };

  const onSubmit = async () => {
    setLoading(true);
    const response = await store.userAccount.createPropertyTransferRequest(
      data
    );
    if (response) {
      setReviewModalVisible(false);
      history.push(`/property_transfers/${response.data.data.uuid}`);
      notification["success"]({
        message: "Create Property Transfer",
        description: "You have succesfully created an asset transfer.",
      });
    } else {
      notification["error"]({
        message: "Create Property Transfer",
        description:
          "An error occurred while saving your property transfer request. Please try again.",
      });
    }
    setLoading(false);
  };

  const items = control.getValues()["items"];
  const controlValues = control.getValues();

  return (
    <DashboardLayout>
      <Page>
        <Wrapper>
          {dataLoading ? (
            <Card>
              <div className="card-body">
                <Skeleton />
                <Skeleton />
              </div>
            </Card>
          ) : (
            <form onSubmit={handleSubmit(onDisplayReview)}>
              <Container>
                <Row>
                  <Col className="p-0">
                    <Card>
                      <CardHeader>
                        Property Transfer Application Form
                      </CardHeader>
                      <CardBody>
                        <div className="card-body">
                          <Section className="mb-3">
                            <FormSection>
                              <FormRow
                                className={errors["type"] ? "has-error" : ""}
                              >
                                <label className="form-title">
                                  Transfer type:
                                </label>
                                <Controller
                                  name="type"
                                  control={control}
                                  as={
                                    <Select
                                      showSearch
                                      optionFilterProp="children"
                                      style={{ minWidth: 300 }}
                                      placeholder="Select type"
                                    >
                                      {Object.keys(config.ptafTypes).map(
                                        (key: string) => (
                                          <Select.Option key={key} value={key}>
                                            {(config.ptafTypes as any)[key]}
                                          </Select.Option>
                                        )
                                      )}
                                    </Select>
                                  }
                                />
                                {errors["type"] ? (
                                  <span className="error-message">
                                    {errors["type"].message}
                                  </span>
                                ) : null}
                              </FormRow>
                              <FormRow
                                className={
                                  errors["recipient"] ? "has-error" : ""
                                }
                              >
                                <label className="form-title">
                                  Transfer to:
                                </label>
                                <Controller
                                  name="recipient"
                                  control={control}
                                  onChange={data => data}
                                  as={
                                    <UserFilter
                                      value={controlValues['recipient']}
                                      searchText={searchRecipientText}
                                      onSearch={(val) => setSearchRecipientText(val)}
                                      onChange={(value: string, text: string) => { }}
                                    />
                                  }
                                />
                                {errors["recipient"] ? (
                                  <span className="error-message">
                                    {errors["recipient"].message}
                                  </span>
                                ) : null}
                              </FormRow>
                              <FormRow
                                className={
                                  errors["remarksField"] ? "has-error" : ""
                                }
                              >
                                <label className="form-title">
                                  Remarks Field:
                                </label>
                                <Controller
                                  name="remarksField"
                                  control={control}
                                  as={
                                    <Input
                                      placeholder="Remarks Field"
                                      value="3333"
                                      style={{ width: 300 }}
                                    />
                                  }
                                />
                                {errors["remarksField"] ? (
                                  <span className="error-message">
                                    {errors["remarksField"].message}
                                  </span>
                                ) : null}
                              </FormRow>
                              {/* <FormRow className={errors['ptafNumber'] ? 'has-error' : ''}>
                        <label className="form-title">PTAF Number:</label>
                        <Controller
                          name="ptafNumber"
                          control={control}
                          defaultValue={ptafDefaultValue}
                          as={<Input placeholder="PTAF Number" value="3333" style={{ width: 300 }} />}
                        />
                        {errors['ptafNumber'] ? (
                          <span className="error-message">{errors['ptafNumber'].message}</span>
                        ) : null}
                      </FormRow> */}

                              <FormRow
                                className={
                                  errors["dateIssued"] ? "has-error" : ""
                                }
                              >
                                <label className="form-title">
                                  Date Issued:
                                </label>
                                <Controller
                                  name="dateIssued"
                                  control={control}
                                  as={
                                    <DatePicker
                                      format="YYYY-MM-DD"
                                      style={{ width: 300 }}
                                    />
                                  }
                                />
                                {errors["dateIssued"] ? (
                                  <span className="error-message">
                                    Date issued is required
                                  </span>
                                ) : null}
                              </FormRow>
                            </FormSection>
                          </Section>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="font-weight-bold">
                          Assets to transfer:
                        </CardTitle>
                        <div className="card-body">
                          <div className="card-title d-flex justify-content-between">
                            {items && Object.keys(items).length ? (
                              <Button
                                outline
                                color="danger"
                                onClick={() => setAssetsModalVisible(true)}
                              >
                                <PlusCircleFilled />
                                Add asset
                              </Button>
                            ) : null}
                          </div>
                          <Section>
                            <div className="items-section">
                              <div className="items-list">
                                {items && Object.keys(items).length ? (
                                  <>
                                    {Object.keys(items).map((key) => {
                                      const asset = items[key];
                                      return (
                                        <AssetItemRowWrapper key={key}>
                                          <span className="asset-row title-row">
                                            <Tooltip title="Asset No">
                                              <span className="title">
                                                {asset.assetTaggingNo}
                                              </span>
                                            </Tooltip>

                                            <Button
                                              outline
                                              color="danger"
                                              onClick={() => {
                                                const tmp = { ...items };
                                                delete tmp[asset.assetId];
                                                control.setValue("items", {
                                                  ...tmp,
                                                });
                                                forceUpdate();
                                              }}
                                            >
                                              Remove
                                            </Button>
                                          </span>
                                          <p className="asset-row">
                                            <Tooltip title="Type/Asset Name">
                                              <span>
                                                <FaToolbox className="icon" />
                                                <span className="value">
                                                  {
                                                    asset.classification
                                                      .description
                                                  }
                                                </span>
                                              </span>
                                            </Tooltip>
                                          </p>
                                          <p className="asset-row">
                                            <Tooltip title="Description/Brand Name">
                                              <span>
                                                <FaTrademark className="icon" />
                                                <span className="value">
                                                  {asset.assetDescription}
                                                </span>
                                              </span>
                                            </Tooltip>
                                            <Tooltip title="Brand">
                                              <span className="value">
                                                {asset.brand}
                                              </span>
                                            </Tooltip>
                                          </p>
                                          <p className="asset-row">
                                            <Tooltip title="cost">
                                              <span>
                                                <FaMoneyBillWaveAlt className="icon" />
                                                <span className="value">
                                                  N/A
                                                </span>
                                              </span>
                                            </Tooltip>
                                          </p>
                                        </AssetItemRowWrapper>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <EmptyMessage>
                                    <img
                                      className="image"
                                      src="https://staging-repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/static/images/empty-items.png"
                                    />
                                    <span className="text text-center">
                                      {" "}
                                      You have no items selected yet.
                                    </span>
                                    <Button
                                      outline
                                      color="danger"
                                      onClick={() =>
                                        setAssetsModalVisible(true)
                                      }
                                    >
                                      Select assets
                                    </Button>
                                  </EmptyMessage>
                                )}
                                {errors["items"] ? (
                                  <span className="error-message mt-3">
                                    Please select assets to transfer
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </Section>

                          <Controller
                            name="items"
                            control={control}
                            onChange={(data) => {
                              setAssetsModalVisible(false);
                              const assets = data[0];
                              return { ...items, ...assets };
                            }}
                            as={
                              <AssetsSelect
                                assets={
                                  items
                                    ? assets.filter(
                                      (asset) =>
                                        !Object.keys(items).includes(
                                          asset.assetId
                                        ) && asset.isGeneric === 0
                                    )
                                    : assets.filter(
                                      (asset) => asset.isGeneric === 0
                                    )
                                }
                                visible={assetsModalVisible}
                                onCancel={() => setAssetsModalVisible(false)}
                              />
                            }
                          />
                        </div>
                        <div className="card-footer d-flex justify-content-end">
                          <Button
                            outline
                            color="danger"
                            htmlType="submit"
                            className=""
                          >
                            Create request
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </form>
          )}
        </Wrapper>
      </Page>

      <Modal
        className="white-content"
        title="Review assets to transfer"
        footer={null}
        width={800}
        onCancel={() => setReviewModalVisible(false)}
        visible={reviewModalVisible}
      >
        {data ? (
          <ModalWrapper>
            <p className="">
              <label className="label">Type:&nbsp;</label>
              <span className="value">{data.type}</span>
            </p>
            <p className="">
              <label className="label">Transfer to:&nbsp;</label>
              <span className="value">
                {
                  store.references.users.filter(
                    (user: UserType) => user.id === data.recipient
                  )[0].fullName
                }
              </span>
            </p>
            <TableWrapper>
              <p className="label font-weight-bold">Assets to transfer:</p>
              <Table
                size="small"
                dataSource={Object.keys(data.items).map(
                  (key: string) => data.items[key]
                )}
                columns={PropertyTransferItemTableColumns}
                pagination={false}
              />
            </TableWrapper>

            <Button
              outline
              color="danger"
              loading={loading}
              onClick={() => onSubmit()}
            >
              Submit Request
            </Button>
          </ModalWrapper>
        ) : null}
      </Modal>
    </DashboardLayout>
  );
});

export default AdminCreatePropertyTransferRequest;
